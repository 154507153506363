import React from "react";
import R14, {
  Colors,
  StyleSheet,
  PopUpMenu,
  PopUpMenuItem,
  IconButton,
  Text,
  DataTable,
  Chip,
  ChipGroup,
  Image,
} from "../core";
import HeaderLabelSelectMenu from "./HeaderLabelSelectMenu";

export default R14.connect(
  class ManualEntryDocumentMetricsDataTable extends React.Component {
    constructor(props) {
      super(props);
      this.initializer = this.initializer.bind(this);
      this.pageLoader = this.pageLoader.bind(this);
      this.cellRenderer = this.cellRenderer.bind(this);
      this.rowKeyExtractor = this.rowKeyExtractor.bind(this);
      this.perms = this.props.app.dm.user.getPermissions(
        "admin",
        "manualEntryUser"
      );
      this.state = {
        parentType: {
          label: "Document Sets",
          value:
            this.props.app.dm.manualEntryPipeline
              .METRIC_PARENT_TYPE_DOCUMENT_SET,
        },
      };
    }
    async getPageData(
      { page, rowsPerPage, sortColumnName, sortDirection, search = null },
      options = {}
    ) {
      let res = await this.props.app.dm.manualEntryPipeline.fetchMetrics(
        this.props.manualEntryPipeline.uid,
        { page, rowsPerPage }
      );
      return {
        pageData: res.nodes,
        totalRows: res.totalCount || null,
      };
    }
    async initializer(gridState) {
      return await this.getPageData(gridState, {
        totalCount: true,
      });
    }
    async pageLoader(gridState) {
      let res = await this.getPageData(gridState);
      return res.pageData;
    }
    get dataTable() {
      return this.props.app.ui.dataTable("manualEntryPipelineMetrics");
    }
    cellRenderer({ cell, columnName, row }) {
      let content = cell;
      let ret = null;
      let style = [styles.cellText];
      let getMetricValueByType = (type, row) => {
        if (!row.metrics) return 0;
        let value = 0;
        for (let metric of row.metrics) {
          if (
            metric.type === type &&
            metric.parentType === this.state.parentType.value
          ) {
            value = metric.value || 0;
            break;
          }
        }
        return value;
      };
      switch (columnName) {
        case "totalComplete":
        case "totalTimeout":
          ret = (
            <Text style={[styles.cellText, styles.nameCellNameText]}>
              {getMetricValueByType(
                columnName === "totalTimeout"
                  ? this.props.app.dm.manualEntryPipeline
                      .METRIC_TYPE_TOTAL_TIMEOUT
                  : this.props.app.dm.manualEntryPipeline
                      .METRIC_TYPE_TOTAL_COMPLETE,
                row
              )}
            </Text>
          );
          break;
        default:
          ret = <Text style={style}>{`${content || 0}`}</Text>;
      }
      return ret;
    }
    rowKeyExtractor(row) {
      return row.dateKey;
    }
    render() {
      return (
        <DataTable
          name={this.props.name || "manualEntryPipelineMetrics"}
          columns={{
            // profileImage: {
            //   label: "image",
            // },
            dateKey: {
              label: "Date",
            },
            totalComplete: {
              label: "Complete",
            },
            totalTimeout: {
              label: "Timeout",
            },
            // total: {
            //   label: "Total",
            // },
          }}
          initializer={this.initializer}
          rowKeyExtractor={this.rowKeyExtractor}
          pageLoader={this.pageLoader}
          cellRenderer={this.cellRenderer}
          initialRowsPerPage={5}
          headerTitle={this.props.title || "Daily Stats"}
          headerControlsRight={[
            <HeaderLabelSelectMenu
              key='headerLabel'
              value={this.state.parentType}
              style={styles.headerLabelSelectMenu}
              searchable={false}
              headerLabelTextStyle={styles.headerLabelTextStyle}
              items={[
                {
                  label: "Document Sets",
                  value:
                    this.props.app.dm.manualEntryPipeline
                      .METRIC_PARENT_TYPE_DOCUMENT_SET,
                },
                {
                  label: "Documents",
                  value:
                    this.props.app.dm.manualEntryPipeline
                      .METRIC_PARENT_TYPE_DOCUMENT,
                },
              ]}
              onValueChange={(value) => {
                this.setState({ parentType: value });
              }}
            />,
          ]}
        />
      );
    }
  }
);
const styles = StyleSheet.create({
  cellText: {
    color: Colors.onSurface,
  },
  cellChipGroup: {
    marginTop: 8,
  },
  headerLabelSelectMenu: {},
  headerLabelTextStyle: {
    fontWeight: 400,
    fontSize: 16,
  },
});
