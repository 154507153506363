import R14 from "../core";

export default class AppModuleDomain extends R14.Domain {
  constructor(key) {
    super();

    this.TYPE_SERVER = "SERVER";
    this.TYPE_REACT_APP = "REACT_APP";
    this.TYPE_AWS_S3_BUCKET = "AWS_S3_BUCKET";
    this.TYPE_REDIS_SERVER = "REDIS_SERVER";
    this.TYPE_AWS_S3_BUCKET = "AWS_S3_BUCKET";
    this.TYPE_R14_STORAGE = "R14_STORAGE";
    this.TYPE_PYTHON_APP = "PYTHON_APP";
    this.TYPE_NODE_APP = "NODE_APP";
    this.TYPE_JAVA_APP = "JAVA_APP";
    this.TYPE_TASK = "TASK";
    this.TYPE_EXECUTABLE_LINUX_64 = "EXECUTABLE_LINUX_64";

    this.PORT_TYPE_NATIVE = "NATIVE";
    this.PORT_TYPE_SERVER = "SERVER";
    this.PORT_TYPE_WEB = "WEB";
    this.PORT_TYPE_WATCH = "WATCH";
    this.STATE_INIT = "INIT";
    this.STATE_INSTALLING = "INSTALLING";
    this.STATE_READY = "READY";
    this.STATE_ERROR = "ERROR";
    this.STATE_REINSTALL = "REINSTALL";
    this.STATE_DELETING = "DELETING";
    this.STATE_UPDATING = "UPDATING";

    this.PORT_TYPE_WATCH = "WATCH";

    this.REPO_TYPE_REACT_APP = "REACT_APP";
    this.REPO_TYPE_REACT_APP_LIB = "REACT_APP_LIB";
    this.REPO_TYPE_SERVER = "SERVER";
    this.REPO_TYPE_SERVER_LIB = "SERVER_LIB";
    this.REPO_TYPE_TASK = "TASK";
    this.REPO_TYPE_TASK_LIB = "TASK_LIB";
    this.REPO_TYPE_PYTHON_APP = "PYTHON_APP";
    this.REPO_TYPE_PYTHON_APP_LIB = "PYTHON_APP_LIB";
    this.REPO_TYPE_REACT_APP_BUILD_WEB = "REACT_APP_BUILD_WEB";
    this.REPO_TYPE_REACT_APP_BUILD_NATIVE = "REACT_APP_BUILD_NATIVE";
    this.REPO_TYPE_SERVER_BUILD = "SERVER_BUILD";
    this.REPO_TYPE_NODE_APP = "NODE_APP";
    this.REPO_TYPE_NODE_APP_LIB = "NODE_APP_LIB";
    this.REPO_TYPE_JAVA_APP = "JAVA_APP";
    this.REPO_TYPE_JAVA_APP_LIB = "JAVA_APP_LIB";

    // this.REPO_TYPE_APP_REACT_APP_CORE = "REACT_APP_CORE";
    // this.REPO_TYPE_APP_SERVER_CORE = "SERVER_CORE";

    this.COMMAND_RUN = "RUN";
    this.COMMAND_RELOAD = "RELOAD";
    this.COMMAND_STOP = "STOP";
    this.SERVER_TYPE_WATCH = "WATCH";
    this.SERVER_TYPE_WEB = "WEB";
    this.SERVER_TYPE_NATIVE = "NATIVE";
    this.SERVER_TYPE_SERVER = "SERVER";
    this.SERVER_TYPE_APP = "APP";
    this.SERVER_TYPE_TASK = "TASK";
    this.SERVER_TYPE_IDE = "IDE";
    this.SERVER_STATE_STOPPED = "STOPPED";
    this.SERVER_STATE_STOPPING = "STOPPING";
    this.SERVER_STATE_STARTING = "STARTING";
    this.SERVER_STATE_RUNNING = "RUNNING";
    this.SERVER_STATE_ERROR = "ERROR";

    this.SERVER_PACKAGE_COMMAND_INSTALL = "INSTALL";
    this.SERVER_PACKAGE_COMMAND_REMOVE = "REMOVE";
    this.SERVER_PACKAGE_COMMAND_UPDATE = "UPDATE";

    this.SERVER_REPO_COMMAND_PUSH = "PUSH";
    this.SERVER_REPO_COMMAND_PULL = "PULL";
    this.SERVER_REPO_COMMAND_COMMIT = "COMMIT";
    this.SERVER_REPO_COMMAND_ADD = "ADD";
    this.SERVER_REPO_COMMAND_ADD_COMMIT_PUSH = "ADD_COMMIT_PUSH";
    this.SERVER_REPO_COMMAND_RESET_HARD = "RESET_HARD";
    this.SERVER_REPO_COMMAND_MERGE = "MERGE";
    this.SERVER_REPO_COMMAND_CHECKOUT = "CHECKOUT";
    this.SERVER_REPO_COMMAND_BRANCH = "BRANCH";
    this.SERVER_REPO_COMMAND_FETCH = "FETCH";

    this.LOG_TYPE_INSTALL = "INSTALL";

    this.VERSION_PYTHON_DEFAULT = "3.6";

    this.IDE_PARENT_TYPE_APP_MODULE = "APP_MODULE";
    this.IDE_PARENT_TYPE_PIPELINE_BLOCK = "PIPELINE_BLOCK";
  }
  async find(fields, options = {}) {
    let fieldsStr =
      typeof fields === "string"
        ? fields
        : this.utils.gql.fieldsToString(fields);
    if (!fieldsStr)
      throw new Error("AppModule Domain Find Error: No fields found");

    // Add Client Filter
    if (!options.filter) options.filter = {};
    options.filter.clientUid = { eq: this.dm.userSession.clientUid };
    if (!options.totalCount) options.totalCount = false;

    let res = await this.api.qry(
      `
      query FindAppModules($page: Int, $resultsPerPage: Int, $totalCount: Boolean!, $sort: [SortOption!]!, $filter: AppModuleFilter) {
        appModules(page: $page, resultsPerPage: $resultsPerPage, sort: $sort, filter: $filter){
          totalCount @include(if: $totalCount)
          nodes {
            ${fieldsStr}
          }
        }
      }`,
      options
    );
    return res.data.appModules;
  }
  // async pageLoader(
  //   { page, rowsPerPage, sortColumnName, sortDirection, search = null },
  //   options = {}
  // ) {
  //   let filter = {
  //     manualEntryPipelineUid: { eq: this.props.manualEntryPipelineUid },
  //   };
  //   if (search) filter.search = { like: `%${search}%` };
  //   if (this.props.type) filter.type = { eq: this.props.type };
  //   let res = await this.manualEntryDocumentDomain.find(
  //     `
  //     uid
  //     name
  //     batchId
  //     totalLowScoreFields
  //     totalLowScoreCharacters
  //     state
  //     reject
  //     valid
  //     updatedAt
  //     `,
  //     {
  //       page: page,
  //       resultsPerPage: rowsPerPage,
  //       filter: filter,
  //       sort: [
  //         {
  //           field: sortColumnName,
  //           order: sortDirection.toUpperCase(),
  //         },
  //       ],
  //       totalCount: options.totalCount || false,
  //     }
  //   );
  //   return {
  //     pageData: res.nodes,
  //     totalRows: res.totalCount || null,
  //   };
  // }

  async create(values, options = { parse: true }) {
    let submitValues = options.parse ? this.parseFormValues(values) : values;
    submitValues.clientUid = this.dm.userSession.clientUid;
    submitValues.log = "[App Module Install] Initializing...";

    let res = await this.api.mutate(
      `
      mutation CreateAppModule($input: CreateAppModuleInput!) {
        createAppModule(input: $input){
          appModule {
            uid
            name
            description
            state
          }
        }
      }`,
      {
        input: submitValues,
      }
    );
    if (res && res.errors && res.errors.length)
      return { success: false, errors: res.errors };
    else
      return {
        success: res && res.data && res.data.createAppModule,
        appModule:
          res.data &&
          res.data.createAppModule &&
          res.data.createAppModule.appModule,
      };
  }
  async update(values, options = { parse: true }) {
    let submitValues = options.parse ? this.parseFormValues(values) : values;
    submitValues.clientUid = this.dm.userSession.clientUid;
    let res = await this.api.mutate(
      `
      mutation UpdateAppModule($input: UpdateAppModuleInput!) {
        updateAppModule(input: $input){
          appModule {
            uid
            description
            state
          }
        }
      }`,
      {
        input: submitValues,
      }
    );
    if (res && res.errors && res.errors.length)
      return { success: false, errors: res.errors };
    else
      return {
        success: res && res.data && res.data.updateAppModule ? true : false,
        appModule:
          res.data &&
          res.data.updateAppModule &&
          res.data.updateAppModule.appModule,
      };
  }
  async updateCore(uid) {
    let res = await this.api.mutate(
      `
      mutation UpdateAppModuleCore($uid: ID!) {
        updateAppModuleCore(uid: $uid){
          appModule {
            uid
            description
            state
          }
        }
      }`,
      {
        uid: uid,
      }
    );
    return true;
  }
  async fixFilePermissions(uid) {
    let res = await this.api.mutate(
      `
      mutation FixAppModuleFilePermissions($uid: ID!) {
        fixAppModuleFilePermissions(uid: $uid){
          appModule {
            uid
          }
        }
      }`,
      {
        uid: uid,
      }
    );
    console.log("FIX APP MNODULE PERMISSIONS?", res);
    return true;
  }
  async runServerCommand(uid, serverType, command) {
    let res = await this.api.mutate(
      `
      mutation RunAppModuleServerCommand($uid: ID!, $serverType: AppModuleServerTypeEnum!, $command: AppModuleServerCommandEnum! ) {
        runAppModuleServerCommand(uid: $uid, type: $serverType, command: $command){
           nodes {
             type
             state
             port
             publicIpAddress
           }
        }
      }`,
      {
        uid,
        serverType,
        command,
      }
    );
    return res && res.data && res.data.runAppModuleServerCommand.nodes
      ? res.data.runAppModuleServerCommand.nodes || []
      : [];
  }
  async runServerPackageCommand(
    uid,
    serverType,
    command,
    packageName,
    version = null
  ) {
    let res = await this.api.mutate(
      `
      mutation RunAppModuleServerPackageCommand($input: AppModuleServerPackageCommandInput!) {
        runAppModuleServerPackageCommand(input: $input){
           uid
        }
      }`,
      {
        input: {
          uid,
          serverType,
          command,
          packageName,
          version,
        },
      }
    );
    return res && res.data && res.data.runAppModuleServerPackageCommand;
  }
  async deleteFull(uid) {
    // let res = await this.api.mutate(
    //   `
    //   mutation DeleteAppModuleFull($uid: ID!) {
    //     deleteAppModuleFull(uid: $uid){
    //        success
    //     }
    //   }`,
    //   {
    //     uid,
    //   }
    // );
    let res = await this.update(
      {
        uid: uid,
        state: this.STATE_DELETING,
        log: "[App Module Install] Deleting...",
      },
      { parse: false }
    );
    return res && res.data && res.data.success;
  }
  async reinstall(uid) {
    let res = await this.update(
      {
        uid: uid,
        state: this.STATE_REINSTALL,
        log: "[App Module Install] Reinstalling...",
      },
      { parse: false }
    );
  }
  async runServerRepoCommand(
    uid,
    repoType,
    command,
    comment = null,
    name = null
  ) {
    let res = await this.api.mutate(
      `
      mutation RunAppModuleServerRepoCommand($input: AppModuleServerRepoCommandInput!) {
        runAppModuleServerRepoCommand(input: $input){
           uid
        }
      }`,
      {
        input: {
          uid,
          repoType,
          command,
          comment,
          name,
        },
      }
    );
    if (res.errors && res.errors.length) throw new Error(res.errors[0].message);
    return res && res.data && res.data.runAppModuleServerRepoCommand;
  }
  // async fetchLog(uid, type) {
  //   let ret = null;
  //   switch (type) {
  //     case this.LOG_TYPE_INSTALL:
  //       ret = await this.fetchInstallLog(uid);
  //       break;
  //     default:
  //       ret = await this.fetchServerLog(uid, type);
  //   }
  //   return ret;
  // }
  async fetchLog(uid) {
    let res = await this.api.qry(
      `
      query FetchAppModuleLog($uid: ID!) {
       appModule(uid: $uid){
          uid
          log
        }
      }`,
      {
        uid: uid,
      }
    );
    return {
      text: res.data.appModule && res.data.appModule.log,
    };
  }
  async fetchServerLog(uid, serverType) {
    let res = null;
    res = await this.api.qry(
      `
        query FetchAppModuleServerLog($uid: ID!, $serverType: AppModuleServerTypeEnum! ) {
          appModuleServerLog(uid: $uid, serverType: $serverType){
            text
          }
        }`,
      {
        uid,
        serverType,
      }
    );
    return res.data.appModuleServerLog || {};
  }
  getHostname() {
    let hostName = window.location.hostname || "r14";
    if (hostName.startsWith("www.")) hostName = hostName.substring(4);
    return hostName;
  }
  createUrl(uid, serverType) {
    return `https://${serverType.toLowerCase()}-${this.utils.str.toHex(
      uid
    )}.am.os.${this.getHostname()}`;
  }
  getRoute(appModule, serverType) {
    let ret = {
      port: null,
      url: null,
      host: `${serverType.toLowerCase()}-${this.utils.str.toHex(
        appModule.uid
      )}.am.os.${this.getHostname()}`,
    };
    switch (appModule.type) {
      case this.TYPE_REDIS_SERVER:
        ret.port = "3008";
        break;
      default:
        ret.port = "443";
        ret.url = `https://${ret.host}.am.os.${this.getHostname()}`;
    }
    return ret;
  }
  parseFormRepoValues(values) {
    let repoTypes = null;
    switch (values.type) {
      case this.TYPE_REACT_APP:
        repoTypes = [this.REPO_TYPE_REACT_APP, this.REPO_TYPE_REACT_APP_LIB];
        break;
      case this.TYPE_SERVER:
        repoTypes = [this.REPO_TYPE_SERVER, this.REPO_TYPE_SERVER_LIB];
        break;
      case this.TYPE_TASK:
        repoTypes = [this.REPO_TYPE_TASK, this.REPO_TYPE_TASK_LIB];
        break;
      case this.TYPE_NODE_APP:
        repoTypes = [this.REPO_TYPE_NODE_APP, this.REPO_TYPE_NODE_APP_LIB];
        break;
      case this.TYPE_PYTHON_APP:
        repoTypes = [this.REPO_TYPE_PYTHON_APP, this.REPO_TYPE_PYTHON_APP_LIB];
        break;
    }
    if (!repoTypes) return null;
    let appModuleRepos = null;
    repoTypes.forEach((type) => {
      let method = "create";
      let vals = {
        uid: null,
        url: null,
        username: null,
        password: null,
      };
      for (let i in vals) {
        let postfix = this.utils.str.capitalize(i);
        if (
          values[`appModuleRepo${postfix}${type}`] &&
          values[`appModuleRepo${postfix}${type}`].trim() !== ""
        )
          vals[i] = values[`appModuleRepo${postfix}${type}`].trim();
      }
      if (vals.uid) {
        if (!appModuleRepos) appModuleRepos = {};
        if (vals.url && vals.username) {
          if (!appModuleRepos.update) appModuleRepos.update = [];
          let updateRepo = {
            uid: vals.uid,
            type: type,
            username: vals.username,
            url: vals.url,
            clientUid: this.dm.userSession.clientUid,
          };
          if (vals.password) updateRepo.password = vals.password;
          appModuleRepos.update.push(updateRepo);
        } else {
          if (!appModuleRepos.delete) appModuleRepos.delete = [];
          appModuleRepos.delete.push(vals.uid);
        }
      } else if (vals.url && vals.username && vals.password) {
        if (!appModuleRepos) appModuleRepos = {};
        if (!appModuleRepos.create) appModuleRepos.create = [];
        appModuleRepos.create.push({
          type: type,
          username: vals.username,
          url: vals.url,
          password: vals.password,
          clientUid: this.dm.userSession.clientUid,
        });
      }
    });
    return appModuleRepos;
  }
  parseFormPortValues(values) {
    let defaultPorts = this.getDefaultPortsByType(values.type);
    let portTypes = defaultPorts ? Object.keys(defaultPorts) : [];
    let appModulePorts = null;
    // switch (values.type) {
    //   case this.TYPE_REACT_APP:
    //     portTypes = [
    //       this.PORT_TYPE_WEB,
    //       this.PORT_TYPE_NATIVE,
    //       this.PORT_TYPE_WATCH
    //     ];
    //     break;
    //   case this.TYPE_SERVER:
    //     portTypes = [this.PORT_TYPE_SERVER];
    //     serverTypes = [this.SERVER_TYPE_SERVER];
    //     break;
    //   case this.TYPE_PYTHON_APP:
    //     serverTypes = [this.SERVER_TYPE_APP];
    //     break;
    // }
    let deletedAppModulePorts = [];
    let createdAppModulePorts = [];
    let updatedAppModulePorts = [];
    if (portTypes) {
      portTypes.forEach(
        (type) =>
          !values.useDefaultPorts &&
          values[`appModulePortPort${type}`] &&
          values[`appModulePortUid${type}`] &&
          updatedAppModulePorts.push({
            uid: values[`appModulePortUid${type}`],
            type: type,
            port: parseInt(values[`appModulePortPort${type}`]),
            clientUid: this.dm.userSession.clientUid,
          })
      );
      portTypes.forEach(
        (type) =>
          !values.useDefaultPorts &&
          values[`appModulePortPort${type}`] &&
          !values[`appModulePortUid${type}`] &&
          createdAppModulePorts.push({
            type: type,
            port: parseInt(values[`appModulePortPort${type}`]),
            clientUid: this.dm.userSession.clientUid,
            resourceUid: values.resourceUid,
          })
      );
      portTypes.forEach(
        (type) =>
          (!values[`appModulePortPort${type}`] || values.useDefaultPorts) &&
          values[`appModulePortUid${type}`] &&
          deletedAppModulePorts.push(values[`appModulePortUid${type}`])
      );
      if (createdAppModulePorts.length) {
        if (!appModulePorts) appModulePorts = {};
        appModulePorts.create = createdAppModulePorts;
      }
      if (updatedAppModulePorts.length) {
        if (!appModulePorts) appModulePorts = {};
        appModulePorts.update = updatedAppModulePorts;
      }
      if (deletedAppModulePorts.length) {
        if (!appModulePorts) appModulePorts = {};
        appModulePorts.delete = deletedAppModulePorts;
      }
    }
    return appModulePorts;
  }
  parseFormValues(values) {
    let isSuperAdmin =
      this.dm.userSession.role === this.dm.user.ROLE_SUPER_ADMIN;
    let ret = {
      name: values.name,
      key: values.key,
      description: values.description,
      aptPackages: values.aptPackages,
      resourceUid: values.resourceUid,
      projectUid: values.projectUid,
      agentCloudAccessKeyUid: values.agentCloudAccessKeyUid || null,
      clientUid: this.dm.userSession.clientUid,
      type: values.type,
      appModuleResourceUid: values.appModuleResourceUid,
      appModuleResourceInstanceTypeKey: values.appModuleResourceInstanceTypeKey,
      gpuAccelerated: values.gpuAccelerated ? true : false,
      // userUids:
      //   values.userUids && values.userUids.length ? values.userUids : null,
    };
    if (values.project) {
      ret.projectUid = values.project.value;
    }
    if (values.users) {
      ret.userUids = values.users.map((user) => user.value);
    }
    if (isSuperAdmin) {
      ret.mountR14OsFs = values.mountR14OsFs;
      ret.mountR14CoreLibraries = values.mountR14CoreLibraries;
    }

    // let portTypes = null;
    let serverTypes = null;
    switch (values.type) {
      case this.TYPE_REACT_APP:
        serverTypes = [
          this.SERVER_TYPE_WEB,
          this.SERVER_TYPE_NATIVE,
          this.SERVER_TYPE_WATCH,
          this.SERVER_TYPE_IDE,
        ];
        break;
      case this.TYPE_SERVER:
        serverTypes = [this.SERVER_TYPE_SERVER, this.SERVER_TYPE_IDE];
        break;
      case this.TYPE_TASK:
        serverTypes = [this.SERVER_TYPE_TASK, this.SERVER_TYPE_IDE];
        break;
      case this.TYPE_NODE_APP:
        serverTypes = [this.SERVER_TYPE_APP, this.SERVER_TYPE_IDE];
        break;
      case this.TYPE_PYTHON_APP:
        serverTypes = [this.SERVER_TYPE_APP, this.SERVER_TYPE_IDE];
        //ret.gpuAccelerated = values.gpuAccelerated || false;
        ret.versionPython = values.versionPython || this.VERSION_PYTHON_DEFAULT;
        break;
      case this.TYPE_REDIS_SERVER:
        serverTypes = [this.SERVER_TYPE_SERVER];
        break;
      case this.TYPE_EXECUTABLE_LINUX_64:
        serverTypes = [this.SERVER_TYPE_APP];
        //ret.gpuAccelerated = values.gpuAccelerated || false;
        if (values.executableFile) ret.executableFile = values.executableFile;
        break;
      case this.TYPE_AWS_S3_BUCKET:
        ret.path = values.path || null;
        break;
    }

    if (values.uid) ret.uid = values.uid;
    else {
      if (serverTypes)
        ret.serverState = serverTypes.map((serverType) => ({
          type: serverType,
          state: this.SERVER_STATE_STOPPED,
        }));
    }

    if (values.password) ret.password = values.password;

    if (!values.expoToken) {
      ret.expoToken = null;
      ret.expoTokenObfuscated = null;
    } else if (values.expoTokenObfuscated !== values.expoToken) {
      ret.expoToken = values.expoToken;
      ret.expoTokenObfuscated = `${values.expoToken.substring(
        0,
        5
      )}*****************`;
    }

    let appModulePorts = this.parseFormPortValues(values);
    if (appModulePorts) ret.appModulePorts = appModulePorts;

    let appModuleRepos = this.parseFormRepoValues(values);

    if (appModuleRepos) ret.appModuleRepos = appModuleRepos;

    return ret;
  }
  getDefaultPortsByType(type) {
    let ret = [];
    switch (type) {
      case this.TYPE_REACT_APP:
        ret = {
          [this.PORT_TYPE_WEB]: 3000,
          [this.PORT_TYPE_NATIVE]: 19002,
          [this.PORT_TYPE_WATCH]: 5001,
        };
        break;
      case this.TYPE_SERVER:
        ret = {
          [this.PORT_TYPE_SERVER]: 3001,
        };
        break;
      default:
      // Do nothing
    }
    return ret;
  }
  async get(uid, options = {}) {
    let serverStateStr = "";
    let resourceStr = "";
    let projectStr = "";
    let reposStr = "";
    if (options.serverState)
      serverStateStr = `serverState {
  type
  state
  port
  publicIpAddress
  resourceDockerTaskUid
}`;

    if (options.resource)
      resourceStr = `resource {
name
uid
state
type
}`;

    if (options.project)
      projectStr = `project {
name
uid
}`;

    if (options.repos)
      reposStr = `appModuleRepos {
  nodes {
    uid
  }
}`;

    let res = await this.api.qry(
      `
      query GetAppModule($uid: ID!) {
       appModule(uid: $uid){
          uid
          type
          state
          name
          description
          ${serverStateStr}
          ${resourceStr}
          ${projectStr}
          ${reposStr}
        }
      }`,
      {
        uid: uid,
      }
    );
    return res.data.appModule;
  }
  async getDetails(uid) {
    let res = await this.api.qry(
      `
    query AppModuleDetails($uid: ID!, $reposFilter: AppModuleRepoFilter, $portsFilter: AppModulePortFilter) {
      appModule(uid: $uid){
        uid
        resourceUid
        projectUid
        key
        name
        description
        type
        appModuleRepos(filter: $reposFilter) {
          nodes {
            uid
            type
            username
            url
            branch
          }
        }
        appModulePorts(filter: $portsFilter) {
          nodes {
            uid
            type
            port
          }
        }
      }
    }
  `,
      {
        // containers: types.Array(
        //   types.Object({
        //     containerId: types.String.isUnique,
        //     name: types.String.isRequired,
        //     state: types.String.isRequired,
        //     exitCode: types.Int,
        //     error: types.String,
        //     image: types.String.isRequired,
        //     ports: types.Array(
        //       types.Object({
        //         containerPort: types.Int,
        //         hostPort: types.Int,
        //         protocol: types.String,
        //       }).isRequired
        //     ),
        //   }).isRequired
        // ),
        uid: uid,
        //projectUid: options.projectUid,
        reposFilter: { clientUid: { eq: this.dm.userSession.clientUid } },
        portsFilter: { clientUid: { eq: this.dm.userSession.clientUid } },
      }
    );
    return res.data.appModule;
  }

  async delete(uid) {
    let res = await this.api.mutate(
      `
      mutation DeleteAppModule($uid: ID!) {
        deleteAppModule(uid: $uid){
          appModule {
            uid
          }
        }
      }`,
      {
        uid: uid,
      }
    );
    return true;
  }
  async onUpdate(uid, callback) {
    return await this.api.subscribe(
      `
      subscription OnUpdateAppModule($uid: ID!) {
        onUpdateAppModule(uid: $uid){
          uid
          state
          serverState {
            type
            state
            port
            publicIpAddress
          }
        }
      }`,
      {
        uid: uid,
      },
      (res) => {
        callback(res.data.onUpdateAppModule);
      }
    );
  }
  async onStreamLog(uid, serverType, callback) {
    return await this.api.subscribe(
      `
      subscription OnStreamAppModuleLog($uid: ID!, $serverType: AppModuleServerTypeEnum) {
        onStreamAppModuleLog(uid: $uid, serverType: $serverType){
          text
        }
      }`,
      {
        uid: uid,
        serverType: serverType,
      },
      (res) => {
        callback(res.data.onStreamAppModuleLog);
      }
    );
  }
  async fetchIdeInfo(appModuleUid) {
    let res = await this.api.qry(
      `
      mutation CreateIdeUrl($input: CreateIdeUrlInput!) {
        createIdeUrl(input: $input){
          url
          appModule {
            uid
            name
            description
            serverState {
              type
              state
              port
              publicIpAddress
              resourceDockerTaskUid
            }
            resource {
              name
              uid
              state
              type
              }
            project {
              name
              uid
            }
              appModuleRepos {
                nodes {
                  uid
                }
              }
          } 
        }
      }`,
      {
        input: {
          parentType: this.dm.appModule.IDE_PARENT_TYPE_APP_MODULE,
          parentUid: appModuleUid,
          userUid: this.dm.userSession.uid,
          clientUid: this.dm.userSession.clientUid,
        },
      }
    );
    if (res.data && res.data.createIdeUrl) {
      return res.data.createIdeUrl;
    } else {
      return null;
    }
  }
  getTypeSelections(options = {}) {
    let projectType = options.projectType || this.dm.project.TYPE_DEV;
    let ret = [];
    switch (projectType) {
      case this.dm.project.TYPE_DEV:
        ret = [
          {
            label: this.getTypeLabel(this.TYPE_REACT_APP),
            value: this.TYPE_REACT_APP,
          },
          {
            label: this.getTypeLabel(this.TYPE_SERVER),
            value: this.TYPE_SERVER,
          },
          {
            label: this.getTypeLabel(this.TYPE_TASK),
            value: this.TYPE_TASK,
          },
          {
            label: this.getTypeLabel(this.TYPE_REDIS_SERVER),
            value: this.TYPE_REDIS_SERVER,
          },
        ];
        break;
      case this.dm.project.TYPE_AI:
        ret = [
          {
            label: this.getTypeLabel(this.TYPE_PYTHON_APP),
            value: this.TYPE_PYTHON_APP,
          },
          {
            label: this.getTypeLabel(this.TYPE_NODE_APP),
            value: this.TYPE_NODE_APP,
          },
          {
            label: this.getTypeLabel(this.TYPE_TASK),
            value: this.TYPE_TASK,
          },
          {
            label: this.getTypeLabel(this.TYPE_REACT_APP),
            value: this.TYPE_REACT_APP,
          },
          {
            label: this.getTypeLabel(this.TYPE_EXECUTABLE_LINUX_64),
            value: this.TYPE_EXECUTABLE_LINUX_64,
          },
          // {
          //   label: this.getTypeLabel(this.TYPE_REDIS_SERVER),
          //   value: this.TYPE_REDIS_SERVER,
          // },
          // {
          //   label: this.getTypeLabel(this.TYPE_AWS_S3_BUCKET),
          //   value: this.TYPE_AWS_S3_BUCKET,
          // },
          // {
          //   label: this.getTypeLabel(this.TYPE_R14_STORAGE),
          //   value: this.TYPE_R14_STORAGE,
          // },
        ];
        break;
      default:
        ret = [];
    }
    return ret;
  }
  getTypeLabel(type) {
    let ret = null;
    switch (type) {
      case this.TYPE_REACT_APP:
        ret = "R14 React App";
        break;
      case this.TYPE_SERVER:
        ret = "R14 Server";
        break;
      case this.TYPE_TASK:
        ret = "R14 Task";
        break;
      case this.TYPE_NODE_APP:
        ret = "Node App";
        break;
      case this.TYPE_PYTHON_APP:
        ret = "Python App";
        break;
      case this.TYPE_EXECUTABLE_LINUX_64:
        ret = "Executable (Linux x64)";
        break;
      case this.TYPE_AWS_S3_BUCKET:
        ret = "AWS S3 Bucket";
        break;
      case this.TYPE_REDIS_SERVER:
        ret = "Redis Server";
        break;
      case this.TYPE_R14_STORAGE:
        ret = "R14 Client Storage";
        break;
    }
    return ret;
  }
  async fetchManageReposFormData(uid) {
    let appModule = await this.getDetails(uid);
    let formData = {
      serverSelections: null,
      repoSelections: null,
      commandSelections: [
        {
          value: this.SERVER_REPO_COMMAND_PULL,
          label: "Pull",
        },
        {
          value: this.SERVER_REPO_COMMAND_ADD_COMMIT_PUSH,
          label: "Add All, Commit, and Push",
        },
        {
          value: this.SERVER_REPO_COMMAND_ADD,
          label: "Add All",
        },
        {
          value: this.SERVER_REPO_COMMAND_COMMIT,
          label: "Commit",
        },
        {
          value: this.SERVER_REPO_COMMAND_PUSH,
          label: "Push",
        },
        {
          value: this.SERVER_REPO_COMMAND_RESET_HARD,
          label: "Reset",
        },
        {
          value: this.SERVER_REPO_COMMAND_MERGE,
          label: "Merge",
        },
        {
          value: this.SERVER_REPO_COMMAND_BRANCH,
          label: "Branch",
        },
        {
          value: this.SERVER_REPO_COMMAND_CHECKOUT,
          label: "Checkout",
        },
        {
          value: this.SERVER_REPO_COMMAND_FETCH,
          label: "Fetch",
        },
      ],
      values: {
        uid: uid,
      },
    };

    let repoSelections = [];
    let packageNameLabel = "NPM Package Name";
    let libraryVersionLabel = "NPM Package Version";
    let appModuleRepoMap = {};
    appModule.appModuleRepos.nodes.forEach((appModuleRepo) => {
      appModuleRepoMap[appModuleRepo.type] = true;
    });
    [
      this.TYPE_REACT_APP,
      this.TYPE_SERVER,
      this.TYPE_TASK,
      this.TYPE_NODE_APP,
      this.TYPE_JAVA_APP,
      this.TYPE_PYTHON_APP,
    ].forEach((type) => {
      appModuleRepoMap[type] &&
        repoSelections.push({
          value: type,
          label: "App",
        });
      if (appModuleRepoMap[`${type}_LIB`])
        repoSelections.push({
          value: `${type}_LIB`,
          label: "Lib",
        });
    });

    // switch (appModule.type) {
    //   case this.TYPE_REACT_APP:
    //     if (appModuleRepoMap[this.REPO_TYPE_REACT_APP])
    //       repoSelections.push({
    //         value: this.REPO_TYPE_REACT_APP,
    //         label: "App",
    //       });
    //     if (appModuleRepoMap[this.REPO_TYPE_REACT_APP_LIB])
    //       repoSelections.push({
    //         value: this.REPO_TYPE_REACT_APP_LIB,
    //         label: "Lib",
    //       });
    //     break;
    //   case this.TYPE_SERVER:
    //     if (appModuleRepoMap[this.REPO_TYPE_SERVER])
    //       repoSelections.push({
    //         value: this.REPO_TYPE_SERVER,
    //         label: "App",
    //       });
    //     if (appModuleRepoMap[this.REPO_TYPE_SERVER_LIB])
    //       repoSelections.push({
    //         value: this.REPO_TYPE_SERVER_LIB,
    //         label: "Lib",
    //       });
    //     break;
    //   case this.TYPE_PYTHON_APP:
    //     if (appModuleRepoMap[this.REPO_TYPE_PYTHON_APP])
    //       repoSelections.push({
    //         value: this.REPO_TYPE_PYTHON_APP,
    //         label: "App",
    //       });
    //     if (appModuleRepoMap[this.REPO_TYPE_PYTHON_APP_LIB])
    //       repoSelections.push({
    //         value: this.REPO_TYPE_PYTHON_APP_LIB,
    //         label: "Lib",
    //       });
    //     break;
    // }
    formData.packageNameLabel = packageNameLabel;
    formData.libraryVersionLabel = libraryVersionLabel;
    if (repoSelections && repoSelections.length)
      formData.repoSelections = repoSelections;
    return formData;
  }
  async fetchManagePackagesFormData(uid) {
    let appModule = await this.get(uid);
    let formData = {
      serverSelections: null,
      commandSelections: [
        {
          value: this.SERVER_PACKAGE_COMMAND_INSTALL,
          label: "Install",
        },
        {
          value: this.SERVER_PACKAGE_COMMAND_REMOVE,
          label: "Remove",
        },
      ],
      values: {
        uid: uid,
        serverType: null,
      },
      packageNameLabel: null,
      versionLabel: null,
    };

    let serverSelections = null;
    let packageNameLabel = "NPM Package Name";
    let libraryVersionLabel = "NPM Package Version";
    let updateLabel = "Update";

    switch (appModule.type) {
      case this.TYPE_REACT_APP:
        serverSelections = [
          {
            value: this.SERVER_TYPE_WEB,
            label: "Web",
          },
          {
            value: this.SERVER_TYPE_NATIVE,
            label: "Native",
          },
        ];
        break;
      case this.TYPE_SERVER:
        serverSelections = [
          {
            value: this.SERVER_TYPE_SERVER,
            label: "Server",
          },
        ];
        break;
      case this.TYPE_TASK:
        serverSelections = [
          {
            value: this.SERVER_TYPE_TASK,
            label: "Task",
          },
        ];
        break;
      case this.TYPE_NODE_APP:
        serverSelections = [
          {
            value: this.SERVER_TYPE_APP,
            label: "App",
          },
        ];
        break;
      case this.TYPE_PYTHON_APP:
        serverSelections = [
          {
            value: this.SERVER_TYPE_APP,
            label: "App",
          },
        ];
        packageNameLabel = "PIP Package Name";
        libraryVersionLabel = "PIP Package Version";
        updateLabel = "Upgrade";
        break;
    }
    formData.commandSelections.push({
      value: this.SERVER_PACKAGE_COMMAND_UPDATE,
      label: updateLabel,
    });
    formData.packageNameLabel = packageNameLabel;
    formData.libraryVersionLabel = libraryVersionLabel;
    if (serverSelections && serverSelections.length) {
      if (serverSelections.length === 1)
        formData.values.serverType = serverSelections[0].value;
      else formData.serverSelections = serverSelections;
    }
    return formData;
  }
  async fetchSelections(filters, options = {}) {
    let filter = {};
    if (filters.search) {
      filter.search = { like: `%${filters.search}%` };
    }
    if (filters.type) {
      filter.type = Array.isArray(filters.type)
        ? { in: filters.type }
        : { eq: filters.type };
    }
    if (filters.projectUid) {
      filter.projectUid = { eq: filters.projectUid };
    }
    if (!this.dm.user.hasAdminRole)
      filter.userUids = { eq: this.dm.userSession.uid };

    let res = await this.dm.appModule.find(
      `
      uid
      name
      `,
      {
        page: 1,
        resultsPerPage: options.resultsPerPage || 10,
        filter: filter,
        totalCount: false,
        sort: [
          {
            field: "name",
            order: "ASC",
          },
        ],
      }
    );
    let ret =
      res && res.nodes
        ? res.nodes.map((val) => ({
            label: val.name,
            value: val.uid,
          }))
        : [];
    return ret;
  }
  async fetchEditFormData(uid = null, options = {}) {
    let formData = {
      typeSelections: this.getTypeSelections(options),
      values: {
        useDefaultPorts: true,
        useDefaultResource: true,
        projectUid: options.projectUid || null,
      },
    };
    let isSuperAdmin =
      this.dm.userSession.role === this.dm.user.ROLE_SUPER_ADMIN;
    // let resourceTypes = [
    //   this.dm.resource.TYPE_EC2_INSTANCE,
    //   this.dm.resource.TYPE_AZURE_VM,
    //   this.dm.resource.TYPE_ECS_CLUSTER,
    //   this.dm.resource.TYPE_AWS_S3_BUCKET,
    // ];

    // let projectResourcesQuery = `
    // project(uid: $projectUid) {
    //   resources(page: $page, resultsPerPage: $resultsPerPage, sort: $sort, filter: $filter){
    //     totalCount @include(if: $totalCount)
    //     nodes {
    //       uid
    //       name
    //     }
    //   }
    //   uid
    //   name
    // }
    // `;

    // if (options.projectUid) formData.values.projectUid = options.projectUid;
    // else throw new Error("ProjectUid Required to create module");

    let res = null;

    if (uid || (options.copyUid && options.projectUid)) {
      let superAdminFields = isSuperAdmin
        ? `
        mountR14OsFs
        mountR14CoreLibraries
      `
        : "";
      let filters = {
        uid: uid || options.copyUid,
        // projectUid: options.projectUid || null,
        reposFilter: { clientUid: { eq: this.dm.userSession.clientUid } },
        portsFilter: { clientUid: { eq: this.dm.userSession.clientUid } },
        // usersFilter: { clientUid: { eq: this.dm.userSession.clientUid } },
        // cloudAccessKeyFilter: {
        //   clientUid: { eq: this.dm.userSession.clientUid },
        //   type: { eq: this.dm.cloudAccessKey.TYPE_R14 },
        // },
      };
      // let resourceParams = "";
      // let resourceQry = "";
      // if (options.copyUid) {
      //   filters.resourcesFilter = {
      //     clientUid: { eq: this.dm.userSession.clientUid },
      //     type: {
      //       in: resourceTypes,
      //     },
      //   };
      //   filters.resourcesSort = [
      //     {
      //       field: "name",
      //       order: "ASC",
      //     },
      //   ];
      //   resourceParams =
      //     "$resourcesFilter: ResourceFilter, $resourcesSort: [SortOption!]!, ";
      //   resourceQry = `
      //     resources(filter: $resourcesFilter, sort: $resourcesSort){
      //       nodes {
      //         uid
      //         name
      //         type
      //       }
      //     }
      //   `;
      // }
      res = await this.api.qry(
        `
      query AppModuleEditFormData($uid: ID!, $reposFilter: AppModuleRepoFilter, $portsFilter: AppModulePortFilter) {
        appModule(uid: $uid){
          uid
          resourceUid
          projectUid
          key
          name
          description
          aptPackages
          expoTokenObfuscated
          type
          userUids
          gpuAccelerated
          appModuleResourceUid
          appModuleResourceInstanceTypeKey
          versionPython
          agentCloudAccessKeyUid
          ${superAdminFields}
          appModuleRepos(filter: $reposFilter) {
            nodes {
              uid
              type
              username
              url
              branch
            }
          }
          appModulePorts(filter: $portsFilter) {
            nodes {
              uid
              type
              port
            }
          }
          users {
            nodes {
              name
              uid
            }
          }
        }
        appModuleResources {
          nodes {
            uid
            resourceUid
            name
            gpu
            instanceTypes {
              key
              name
              description
            }
          }
        }
      }
    `,
        filters
      );

      // else {
      //   res = await this.api.qry(
      //     `
      //   query AppModuleCreateFormData($projectUid: ID!, $sort: [SortOption!]!, $filter: ResourceFilter, $usersFilter: UserFilter, $cloudAccessKeyFilter: CloudAccessKeyFilter) {
      //     cloudAccessKeys(filter: $cloudAccessKeyFilter){
      //       nodes {
      //         uid
      //         name
      //         type
      //       }
      //     }
      //   }
      //   `,
      //     {
      //       projectUid: options.projectUid,
      //       // page: 1,
      //       // resultsPerPage: 100,
      //       totalCount: false,
      //       sort: [
      //         {
      //           field: "name",
      //           order: "ASC",
      //         },
      //       ],
      //       filter: {
      //         clientUid: { eq: this.dm.userSession.clientUid },
      //         type: { in: resourceTypes },
      //       },
      //       usersFilter: { clientUid: { eq: this.dm.userSession.clientUid } },
      //       cloudAccessKeyFilter: {
      //         clientUid: { eq: this.dm.userSession.clientUid },
      //         type: { eq: this.dm.cloudAccessKey.TYPE_R14 },
      //       },
      //     }
      //   );
      // }
      if (res.data.appModule) {
        formData.values.uid = res.data.appModule.uid || null;

        if (options.copyUid) {
          formData.values.uid = null;
          formData.values.name = res.data.appModule.name + " Copy";
          formData.values.key = res.data.appModule.key + "-copy";
        } else {
          formData.values.name = res.data.appModule.name;
          formData.values.key = res.data.appModule.key;
        }

        formData.values.executableFile =
          res.data.appModule.executableFile || null;

        formData.values.description = res.data.appModule.description;
        formData.values.aptPackages = res.data.appModule.aptPackages || null;
        formData.values.expoToken =
          res.data.appModule.expoTokenObfuscated || null;
        formData.values.expoTokenObfuscated =
          res.data.appModule.expoTokenObfuscated || null;
        formData.values.resourceUid = res.data.appModule.resourceUid;
        formData.values.projectUid = res.data.appModule.projectUid;
        formData.values.type = res.data.appModule.type;

        formData.values.userUids = res.data.appModule.userUids;

        formData.values.appModuleResourceUid =
          res.data.appModule.appModuleResourceUid;
        formData.values.appModuleResourceInstanceTypeKey =
          res.data.appModule.appModuleResourceInstanceTypeKey;
        formData.values.gpuAccelerated = res.data.appModule.gpuAccelerated;
        formData.values.versionPython = res.data.appModule.versionPython;
        formData.values.agentCloudAccessKeyUid =
          res.data.appModule.agentCloudAccessKeyUid || null;

        if (isSuperAdmin) {
          formData.values.mountR14CoreLibraries =
            res.data.appModule.mountR14CoreLibraries;
          formData.values.mountR14OsFs = res.data.appModule.mountR14OsFs;
        }

        if (
          res.data.appModule.appModulePorts &&
          res.data.appModule.appModulePorts.nodes &&
          res.data.appModule.appModulePorts.nodes.length
        ) {
          formData.values.useDefaultPorts = false;
          res.data.appModule.appModulePorts.nodes.forEach((port) => {
            formData.values[`appModulePortUid${port.type}`] = options.copyUid
              ? null
              : port.uid;
            formData.values[`appModulePortPort${port.type}`] = port.port;
          });
        }
        if (
          res.data.appModule.users &&
          res.data.appModule.users.nodes &&
          res.data.appModule.users.nodes.length
        ) {
          formData.values.users = res.data.appModule.users.nodes.map(
            (user) => ({
              label: user.name,
              value: user.uid,
            })
          );
        }
        if (
          (res.data.appModule.appModuleRepos &&
            res.data.appModule.appModuleRepos.nodes,
          res.data.appModule.appModuleRepos.nodes.length)
        ) {
          res.data.appModule.appModuleRepos.nodes.forEach((repo) => {
            formData.values[`appModuleRepoUid${repo.type}`] = options.copyUid
              ? null
              : repo.uid;
            formData.values[`appModuleRepoUsername${repo.type}`] =
              repo.username;
            formData.values[`appModuleRepoUrl${repo.type}`] = repo.url;
          });
        }
      }
    } else {
      res = await this.api.qry(
        `
      query AppModuleResources {
        appModuleResources {
          nodes {
            uid
            resourceUid
            name
            gpu
            instanceTypes {
              key
              name
              description
            }
          }
        }
      }
    `
      );

      // Set the current user as default for users.
      formData.values.users = [
        {
          label: this.dm.userSession.name,
          value: this.dm.userSession.uid,
        },
      ];
    }
    // formData.userSelections = res.data.project.users.nodes.map((vals) => ({
    //   label: vals.name,
    //   value: vals.uid,
    // }));

    // formData.cloudAccessKeySelections = res.data.cloudAccessKeys.nodes.map(
    //   (vals) => ({
    //     label: vals.name,
    //     value: vals.uid,
    //   })
    // );
    formData.appModuleResources =
      res &&
      res.data &&
      res.data.appModuleResources &&
      res.data.appModuleResources.nodes
        ? res.data.appModuleResources.nodes
        : [];

    formData.versionPythonSelections = [
      {
        label: "3.6",
        value: "3.6",
      },
      {
        label: "3.9",
        value: "3.9",
      },
      {
        label: "2.7",
        value: "2.7",
      },
    ];

    // if (
    //   res.data &&
    //   res.data.project &&
    //   res.data.project.resources &&
    //   res.data.project.resources.nodes
    // ) {
    //   formData.resourceSelections = res.data.project.resources.nodes.map(
    //     (vals) => ({
    //       label: vals.name,
    //       type: vals.type,
    //       value: vals.uid,
    //     })
    //   );
    // }

    return formData;
  }
  getResourceSelectionsByType(type, resourceSelections) {
    let ret = [];
    switch (type) {
      case this.TYPE_AWS_S3_BUCKET:
        ret = resourceSelections.filter(
          (r) => r.type === this.dm.resource.TYPE_AWS_S3_BUCKET
        );
        break;
      default:
        ret = resourceSelections.filter(
          (r) => r.type !== this.dm.resource.TYPE_AWS_S3_BUCKET
        );
        break;
    }
    return ret;
  }
  getStateLabel(state, resourceState) {
    let label = null;
    switch (resourceState) {
      case "running":
      case "available":
      case "active":
        switch (state) {
          case this.STATE_INIT:
            label = "Initializing";
            break;
          case this.STATE_INSTALLING:
          case this.STATE_REINSTALL:
            label = "Installing";
            break;
          case this.STATE_DELETING:
            label = "Deleting";
            break;
          case this.STATE_READY:
            label = "Ready";
            break;
          case this.STATE_ERROR:
            label = "Error";
            break;
          case this.STATE_UPDATING:
            label = "Updating";
            break;
        }
        break;
      case "stopped":
      case "deallocated":
        label = "Resource Stopped";
        break;
      default:
        label = "Resource Pending";
    }

    return label;
  }
  getStateIndicatorColor(state, resourceState) {
    let color = "yellow";
    switch (resourceState) {
      case "running":
      case "available":
      case "active":
        switch (state) {
          case this.STATE_INIT:
          case this.STATE_INSTALLING:
            color = "yellow";
            break;
          case this.STATE_READY:
            color = "green";
            break;
          case this.STATE_ERROR:
            color = "red";
            break;
        }
        break;
      case "stopped":
      case "deallocated":
        color = "red";
        break;
    }
    return color;
  }
  getServerStateLabel(state) {
    let label = null;
    switch (state) {
      case this.SERVER_STATE_STOPPED:
        label = "Stopped";
        break;
      case this.SERVER_STATE_STOPPING:
        label = "Stopping";
        break;
      case this.SERVER_STATE_STARTING:
        label = "Starting";
        break;
      case this.SERVER_STATE_RUNNING:
        label = "Running";
        break;
      case this.SERVER_STATE_ERROR:
        label = "Error";
        break;
    }
    return label;
  }
  getServerStateIndicatorColor(state) {
    let color = "red";
    switch (state) {
      case this.SERVER_STATE_STARTING:
        color = "yellow";
        break;
      case this.SERVER_STATE_RUNNING:
        color = "green";
        break;
    }
    return color;
  }
}
