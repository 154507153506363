import React from "react";
import R14, {
  Redirect,
  HiddenInputField,
  BackButton,
  Form,
  SubmitButton,
  TextInputField,
  SelectMenuField,
  FieldGroup,
  FadeView,
  SwitchField,
  StyleSheet,
  FileInputField,
} from "../core";
export default R14.connect(
  class AppModuleEditScreen extends React.Component {
    constructor(props) {
      super(props);
      this.userItemLoader = this.userItemLoader.bind(this);
      this.projectItemLoader = this.projectItemLoader.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleProjectValueChange = this.handleProjectValueChange.bind(this);
      this.handleTypeValueChange = this.handleTypeValueChange.bind(this);
      this.handleAppModuleResourceUidValueChange =
        this.handleAppModuleResourceUidValueChange.bind(this);
      //this.handUseAgentValueChange = this.handUseAgentValueChange.bind(this);
      this.handleUseDefaultPortsValueChange =
        this.handleUseDefaultPortsValueChange.bind(this);
      this.handleUseDefaultResourceValueChange =
        this.handleUseDefaultResourceValueChange.bind(this);
      this.formData = this.props.formData || {};

      // console.warn("TEMP ADD VALS");
      // this.formData.values = {
      //   projectUid: "UA1cCsLOS",
      //   name: "Dev Test App",
      //   key: "dev-test-app",
      //   description: null,
      //   userUids: ["NlbrT8ZlJ"],
      //   type: "REACT_APP",
      //   resourceUid: "lgJrtoFNY",
      //   appModulePortPortWEB: "3000",
      //   appModulePortPortNATIVE: "19002",
      //   appModulePortPortWATCH: "5001",
      //   appModuleRepoUrlREACT_APP:
      //     "https://github.com/hgmventures/exela-sign-react-app",
      //   appModuleRepoUsernameREACT_APP: "steveswallow",
      //   appModuleRepoPasswordREACT_APP: "N1rVaN@89!",
      //   appModuleRepoUrlREACT_APP_LIB: null,
      //   appModuleRepoUsernameREACT_APP_LIB: null,
      //   appModuleRepoPasswordREACT_APP_LIB: null
      // };

      this.isEditMode =
        this.formData.values && this.formData.values.uid ? true : false;
      this.hasType =
        this.formData.values && this.formData.values.type ? true : false;
      this.selectProject =
        this.formData.values && this.formData.values.projectUid ? false : true;

      this.state = {
        type: this.hasType ? this.formData.values.type : null,
        useDefaultPorts:
          this.formData.values && this.formData.values.useDefaultPorts
            ? true
            : false,
        useDefaultResource: true,
        // this.formData.values && this.formData.values.useDefaultResource
        //   ? true
        //   : false,
        // useAgent:
        //   this.formData.values && this.formData.values.agentCloudAccessKeyUid
        //     ? true
        //     : false,
        projectUid:
          this.formData.values && this.formData.values.projectUid
            ? this.formData.values.projectUid
            : false,
      };

      this.appModuleDomain = this.props.app.dm.appModule;
      this.portInputs = {};
    }
    componentDidMount() {
      this.updateResource(this.state.type, { init: true });
    }

    get form() {
      return this.props.app.ui.form("appModuleForm");
    }

    async userItemLoader(filters) {
      if (!this.state.projectUid) return [];
      filters.projectUid = this.state.projectUid;
      return await this.props.app.dm.project.fetchUserSelections(filters);
    }
    async projectItemLoader(filters) {
      return await this.props.app.dm.project.fetchSelections(filters);
    }

    updateResource(type, options = {}) {
      let resourceSelections = [];
      let disabled = true;
      let appModuleResourceUid = null;
      let appModuleResourceInstanceTypeKey = null;
      let appModuleResourceMap = {};
      let resourceUid = null;
      if (type) {
        let resources = [];
        for (let appModuleResource of this.formData.appModuleResources) {
          if (appModuleResource.gpu) {
            if (
              [
                this.appModuleDomain.TYPE_PYTHON_APP,
                this.appModuleDomain.TYPE_EXECUTABLE_LINUX_64,
              ].includes(type)
            )
              resources.push(appModuleResource);
          } else resources.push(appModuleResource);
          resourceSelections = resources.map((r) => {
            appModuleResourceMap[r.uid] = r;
            return {
              label: `${r.name}`,
              value: r.uid,
            };
          });
        }
        disabled = false;
        if (
          options.init &&
          this.formData &&
          this.formData.values.appModuleResourceUid &&
          this.formData.values.appModuleResourceUid in appModuleResourceMap
        ) {
          appModuleResourceUid = this.formData.values.appModuleResourceUid;
          appModuleResourceInstanceTypeKey =
            this.formData.values.appModuleResourceInstanceTypeKey || null;
          resourceUid =
            appModuleResourceMap[appModuleResourceUid].resourceUid || null;
        }
      }
      this.form.elmts.appModuleResourceUid.setDisabled(disabled);
      this.form.elmts.appModuleResourceUid.setItems(resourceSelections);
      this.form.elmts.appModuleResourceUid.setValue(appModuleResourceUid);
      this.form.elmts.resourceUid.setValue(resourceUid);
      this.form.elmts.appModuleResourceInstanceTypeKey.setDisabled(disabled);
      this.updateResourceInstanceTypeKey(
        appModuleResourceUid,
        appModuleResourceInstanceTypeKey
      );
    }
    updateResourceInstanceTypeKey(
      appModuleResourceUid,
      appModuleResourceInstanceTypeKey = null
    ) {
      let resourceUid = null;
      let gpuAccelerated = false;
      let instanceTypeElmt = this.form.elmts.appModuleResourceInstanceTypeKey;
      this.formData.appModuleResources
        .filter((r) => r.uid === appModuleResourceUid)
        .forEach((r) => {
          resourceUid = r.resourceUid;
          if (r.gpu && parseInt(r.gpu) > 0) gpuAccelerated = true;
          instanceTypeElmt.setItems(
            r.instanceTypes.map((i) => ({
              label: `${i.name} (${i.description})`,
              value: i.key,
            }))
          );
        });

      // Just choose first item
      if (!appModuleResourceInstanceTypeKey && instanceTypeElmt.items.length)
        appModuleResourceInstanceTypeKey = instanceTypeElmt.items[0].value;

      this.form.elmts.resourceUid.setValue(resourceUid);
      this.form.elmts.gpuAccelerated.setValue(gpuAccelerated);

      instanceTypeElmt.setDisabled(appModuleResourceUid ? false : true);
      instanceTypeElmt.setValue(appModuleResourceInstanceTypeKey);
    }
    handleAppModuleResourceUidValueChange(value) {
      this.updateResourceInstanceTypeKey(value);
    }
    async handleTypeValueChange(type) {
      this.setState({
        type: type || null,
      });
      this.updateResource(type);
    }
    async handleProjectValueChange(project = null) {
      this.setState({
        projectUid: project ? project.value : null,
      });
    }
    async handleUseDefaultPortsValueChange(value, elmt) {
      this.setState({
        useDefaultPorts: value ? true : false,
      });
    }
    async handleUseDefaultResourceValueChange(value, elmt) {
      this.setState({
        useDefaultResource: value ? true : false,
      });
    }
    // async handUseAgentValueChange(value, elmt) {
    //   this.setState({
    //     useAgent: value ? true : false,
    //   });
    // }
    async handleSubmit(form) {
      if (this.props.onSubmit) return await this.props.onSubmit(form);
      let editMethod = this.isEditMode ? "update" : "create";
      let ret = null;
      this.props.app.ui.progressIndicator.show();
      try {
        let res = await this.appModuleDomain[editMethod](form.values);
        if (res.success) {
          let dataTable = this.props.refreshDataTable || "projects";
          this.props.app.ui.dataTable.exists(dataTable) &&
            (await this.props.app.ui.dataTable(dataTable).refresh());
          ret = <Redirect to={this.props.redirect || "projectAppModules"} />;
        } else if (res.errors) {
          res.errors.forEach((err) => form.addError(err.message));
        } else form.addError("Unknown Error, please try again.");
      } catch (err) {
        form.addError(err.message);
      }
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
      return ret;
    }
    renderHiddenInputs() {
      let hiddenInputs = [
        <HiddenInputField key='resourceUid' name='resourceUid' />,
        <HiddenInputField key='gpuAccelerated' name='gpuAccelerated' />,
      ];
      if (this.isEditMode) {
        hiddenInputs.push(<HiddenInputField key='uid' name='uid' />);
        // hiddenInputs.push(<HiddenInputField key='type' name='type' />);
        // Render the hidden port uids
        let portTypes = [];
        let repoTypes = [];
        switch (this.state.type) {
          case this.appModuleDomain.TYPE_REACT_APP:
            portTypes = [
              this.appModuleDomain.PORT_TYPE_WEB,
              this.appModuleDomain.PORT_TYPE_NATIVE,
              this.appModuleDomain.PORT_TYPE_WATCH,
            ];
            repoTypes = [
              this.appModuleDomain.REPO_TYPE_REACT_APP,
              this.appModuleDomain.REPO_TYPE_REACT_APP_LIB,
            ];
            break;
          case this.appModuleDomain.TYPE_SERVER:
            portTypes = [this.appModuleDomain.PORT_TYPE_SERVER];
            repoTypes = [
              this.appModuleDomain.REPO_TYPE_SERVER,
              this.appModuleDomain.REPO_TYPE_SERVER_LIB,
            ];
            break;
          case this.appModuleDomain.TYPE_PYTHON_APP:
            repoTypes = [
              this.appModuleDomain.REPO_TYPE_PYTHON_APP,
              this.appModuleDomain.REPO_TYPE_PYTHON_APP_LIB,
            ];
            break;
          case this.appModuleDomain.TYPE_TASK:
            repoTypes = [
              this.appModuleDomain.REPO_TYPE_TASK,
              this.appModuleDomain.REPO_TYPE_TASK_LIB,
            ];
            break;
        }
        portTypes.forEach((type) =>
          hiddenInputs.push(
            <HiddenInputField
              key={`appModulePortUid${type}`}
              name={`appModulePortUid${type}`}
            />
          )
        );
        repoTypes.forEach((type) =>
          hiddenInputs.push(
            <HiddenInputField
              key={`appModuleRepoUid${type}`}
              name={`appModuleRepoUid${type}`}
            />
          )
        );
      }
      if (!this.selectProject) {
        hiddenInputs.push(
          <HiddenInputField key='projectUid' name='projectUid' />
        );
      }
      return hiddenInputs;
    }
    renderPortInputs() {
      if (!this.portInputs[this.state.type]) {
        switch (this.state.type) {
          case this.appModuleDomain.TYPE_REACT_APP:
            this.portInputs[this.state.type] = (
              <FieldGroup key='reactAppPorts'>
                <TextInputField
                  name={`appModulePortPort${this.appModuleDomain.PORT_TYPE_WEB}`}
                  label='Web Port'
                  helper='Default to 3000'
                  // required='Please enter a web port.'
                />
                <TextInputField
                  name={`appModulePortPort${this.appModuleDomain.PORT_TYPE_NATIVE}`}
                  label='Native Port'
                  helper='Default to 19002'
                  // required='Please enter a native port.'
                />
                <TextInputField
                  name={`appModulePortPort${this.appModuleDomain.PORT_TYPE_WATCH}`}
                  label='Watch Port'
                  helper='Default to 5001'
                  // required='Please enter a watch port.'
                />
              </FieldGroup>
            );
            break;
          case this.appModuleDomain.PORT_TYPE_SERVER:
            this.portInputs[this.state.type] = (
              <TextInputField
                key='serverPorts'
                name={`appModulePortPort${this.appModuleDomain.PORT_TYPE_SERVER}`}
                label='Server Port'
                helper='Default to 3001'
                // required='Please enter a server port.'
              />
            );
            break;
          default:
          // Do Nothing
        }
      }
      return (
        <FadeView
          visible={
            this.state.type && this.portInputs[this.state.type] ? true : false
          }
        >
          <SwitchField
            key='portSwitch'
            name='useDefaultPorts'
            label='Use Default Port(s)'
            onValueChange={this.handleUseDefaultPortsValueChange}
            value={this.state.useDefaultPorts}
          />
          <FadeView
            visible={!this.state.useDefaultPorts}
            // unmountOnExit={false}
          >
            {this.portInputs[this.state.type]}
          </FadeView>
        </FadeView>
      );
    }
    renderRepoInputs() {
      let repoInputs = [];
      let repoTypes = [];
      switch (this.state.type) {
        case this.appModuleDomain.TYPE_REACT_APP:
          repoTypes = [
            {
              label: "App Git Repository",
              key: this.appModuleDomain.REPO_TYPE_REACT_APP,
            },
            {
              label: "Shared Lib Git Repository",
              key: this.appModuleDomain.REPO_TYPE_REACT_APP_LIB,
            },
          ];
          break;
        case this.appModuleDomain.REPO_TYPE_SERVER:
          repoTypes = [
            {
              label: "App Git Repository",
              key: this.appModuleDomain.REPO_TYPE_SERVER,
            },
            {
              label: "Shared Lib Git Repository",
              key: this.appModuleDomain.REPO_TYPE_SERVER_LIB,
            },
          ];
          break;
        case this.appModuleDomain.REPO_TYPE_TASK:
          repoTypes = [
            {
              label: "App Git Repository",
              key: this.appModuleDomain.REPO_TYPE_TASK,
            },
            {
              label: "Shared Lib Git Repository",
              key: this.appModuleDomain.REPO_TYPE_TASK_LIB,
            },
          ];
          break;
        case this.appModuleDomain.REPO_TYPE_NODE_APP:
          repoTypes = [
            {
              label: "App Git Repository",
              key: this.appModuleDomain.REPO_TYPE_NODE_APP,
            },
            {
              label: "Shared Lib Git Repository",
              key: this.appModuleDomain.REPO_TYPE_NODE_APP_LIB,
            },
          ];
          break;
        case this.appModuleDomain.REPO_TYPE_PYTHON_APP:
          repoTypes = [
            {
              label: "App Git Repository",
              key: this.appModuleDomain.REPO_TYPE_PYTHON_APP,
            },
            {
              label: "Shared Lib Git Repository",
              key: this.appModuleDomain.REPO_TYPE_PYTHON_APP_LIB,
            },
          ];
          break;
      }
      repoTypes.forEach((type) => {
        repoInputs.push(
          <FieldGroup key={type.key} label={type.label}>
            <TextInputField
              autoComplete={false}
              name={`appModuleRepoUrl${type.key}`}
              label='Url'
              // required='Please enter a url.'
            />
            <TextInputField
              autoComplete={false}
              name={`appModuleRepoUsername${type.key}`}
              label='Username'
              // required='Please enter a username.'
            />
            <TextInputField
              autoComplete={false}
              name={`appModuleRepoPassword${type.key}`}
              label={this.isEditMode ? "Change Password" : "Password"}
              // required={this.isEditMode ? false : "Please enter a password."}
              secure
            />
          </FieldGroup>
        );
      });
      return repoInputs;
    }
    renderResourceInputs() {
      return (
        <FieldGroup key='resource' label='Resource'>
          <SelectMenuField
            useValuesOnly
            onValueChange={this.handleAppModuleResourceUidValueChange}
            name='appModuleResourceUid'
            label='Resource'
            required='Please select a resource'
            // items={this.formData.cpuSelections}
          />
          <SelectMenuField
            useValuesOnly
            name='appModuleResourceInstanceTypeKey'
            label='Instance Type'
            required='Please select a instance type'
            // items={this.formData.cpuSelections}
          />
        </FieldGroup>
      );
    }
    renderSuperAdminInputs() {
      let isSuperAdmin =
        this.props.app.dm.userSession.role ===
        this.props.app.dm.user.ROLE_SUPER_ADMIN;
      if (!isSuperAdmin) return null;
      return (
        <FieldGroup label='Super Admin Options'>
          <SwitchField
            key='mountR14OsFs'
            name='mountR14OsFs'
            label='Mount R14 Os Fs'
          />

          <SwitchField
            key='mountR14CoreLibraries'
            name='mountR14CoreLibraries'
            label='Mount R14 Core Libs'
          />
        </FieldGroup>
      );
    }
    render() {
      return (
        <Form
          name='appModuleForm'
          onSubmit={this.handleSubmit}
          validateBeforeSubmit
          initialValues={this.formData.values}
          controlsBottomRight={[
            <BackButton title='Cancel' key='cancel' variant='text' />,
            <SubmitButton
              style={styles.submitButton}
              title={this.isEditMode ? "Update" : "Create"}
              key='submit'
            />,
          ]}
        >
          {this.renderHiddenInputs()}

          <FieldGroup>
            <TextInputField
              name='name'
              label='Name'
              required='Please enter a name.'
              autoFocus
            />
            <TextInputField
              name='key'
              label='Key'
              required='Please enter a key.'
              helper='Used as remote folder name'
            />
          </FieldGroup>

          <TextInputField name='description' label='Description' />

          {this.selectProject && (
            <SelectMenuField
              name='project'
              label='Project'
              required='Please select a project'
              searchable
              itemLoader={this.projectItemLoader}
              autoLoad
              onValueChange={this.handleProjectValueChange}
            />
          )}

          <FadeView visible={this.state.projectUid}>
            <SelectMenuField
              //useValuesOnly
              name='users'
              multiple
              label='Users'
              searchable
              itemLoader={this.userItemLoader}
              autoLoad
              //items={this.props.formData.userSelections}
              required='Please select at least 1 user.'
            />
          </FadeView>

          <SelectMenuField
            disabled={this.isEditMode}
            useValuesOnly
            name='type'
            label='Type'
            items={this.formData.typeSelections}
            onValueChange={this.handleTypeValueChange}
            required='Please select a type.'
          />

          {[this.appModuleDomain.TYPE_EXECUTABLE_LINUX_64].includes(
            this.state.type
          ) && (
            <FileInputField
              name='executableFile'
              label={
                this.isEditMode ? "Change Executable File" : "Executable File"
              }
              required={
                this.isEditMode ? false : "Please upload an executable file."
              }
            />
          )}

          {this.state.useDefaultResource &&
            [this.appModuleDomain.TYPE_PYTHON_APP].includes(
              this.state.type
            ) && (
              <SelectMenuField
                useValuesOnly
                name='versionPython'
                label='Python Version'
                items={this.formData.versionPythonSelections}
                required='Please select a version.'
              />
            )}

          {[this.appModuleDomain.TYPE_AWS_S3_BUCKET].includes(
            this.state.type
          ) && (
            <TextInputField name='path' label='Path' helper='path/to/files' />
          )}

          {[this.appModuleDomain.TYPE_REDIS_SERVER].includes(
            this.state.type
          ) && (
            <TextInputField
              name='password'
              label={this.isEditMode ? "Change Password" : "Password"}
              secure
            />
          )}

          {this.state.useDefaultResource &&
            [
              this.appModuleDomain.TYPE_PYTHON_APP,
              this.appModuleDomain.TYPE_REACT_APP,
              this.appModuleDomain.TYPE_SERVER,
              this.appModuleDomain.TYPE_TASK,
              this.appModuleDomain.TYPE_EXECUTABLE_LINUX_64,
            ].includes(this.state.type) && (
              <TextInputField
                name='aptPackages'
                label='APT Packages'
                helper='Comma-separated'
              />
            )}

          {this.state.useDefaultResource &&
            [this.appModuleDomain.TYPE_REACT_APP].includes(this.state.type) && (
              <React.Fragment>
                <HiddenInputField name='expoTokenObfuscated' />
                <TextInputField
                  autoComplete={false}
                  name='expoToken'
                  label='Expo Access Token'
                />
              </React.Fragment>
            )}

          {/* <SwitchField
            key='useAgentSwitch'
            name='useAgent'
            label='Use R14 Os Agent'
            onValueChange={this.handUseAgentValueChange}
            value={this.state.useAgent}
          />
          <FadeView visible={this.state.useAgent}>
            <SelectMenuField
              useValuesOnly
              name='agentCloudAccessKeyUid'
              label='Agent Cloud Access Key'
              items={this.formData.cloudAccessKeySelections}
            />
          </FadeView> */}
          {this.renderResourceInputs()}
          {this.renderSuperAdminInputs()}
          {this.renderPortInputs()}

          {this.renderRepoInputs()}

          {/* <SectionHeader level={5}>Build Git Repository</SectionHeader>
          <FieldGroup>
            <TextInputField name='reposBuildUrl' label='Url' />
            <TextInputField name='reposBuildUsername' label='Username' />
            <TextInputField name='reposBuildPassword' label='Password' secure />
          </FieldGroup> */}
        </Form>
      );
    }
  }
);
const styles = StyleSheet.create({
  submitButton: {
    marginLeft: 4,
  },
});
