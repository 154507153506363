import React from "react";
import R14, { Colors, StyleSheet, Text, PopUpMenuItem } from "../core";
import EntityDataTable from "./EntityDataTable";
import StateIndicatorText from "./StateIndicatorText";
export default R14.connect(
  class ManualEntryDocumentDataTable extends React.Component {
    constructor(props) {
      super(props);
      this.appModuleBuildDomain = this.props.app.dm.appModuleBuild;
      this.pageLoader = this.pageLoader.bind(this);
      this.perms = this.props.app.dm.user.getPermissions(
        this.projectKey,
        "appModule"
      );
    }
    get projectKey() {
      return this.props.app.dm.project.getKeyByType(this.props.projectType);
    }
    async handleRebuildPress(uid) {
      let ret = false;
      let errorMessage = "Unknown Error, please try again.";
      this.props.app.ui.progressIndicator.show();
      try {
        let res = await this.appModuleBuildDomain.rebuild(uid);
        if (res.success) {
          ret = true;
          await this.props.app.ui.dataTable("appModuleBuilds").refresh();
        } else if (res.errors && res.errors.length)
          errorMessage = res.errors[0];
      } catch (err) {
        errorMessage = err.message;
        console.error(err);
      }
      this.props.app.ui.progressIndicator.hide({ timeout: 500 });
      this.props.app.ui.snackBar.show({
        message: ret ? "Rebuilding build." : errorMessage,
        variant: ret ? "success" : "error",
      });
      return ret;
    }
    async pageLoader(
      { page, rowsPerPage, sortColumnName, sortDirection, search = null },
      options = {}
    ) {
      let filter = {
        projectUid: { eq: this.props.projectUid },
      };
      if (search) filter.search = { like: `%${search}%` };
      if (this.props.type) filter.type = { eq: this.props.type };

      let res = await this.appModuleBuildDomain.find(
        `
        uid
        description
        version
        appModule {
          name
        }
        state
        createdAt
        `,
        {
          page: page,
          resultsPerPage: rowsPerPage,
          filter: filter,
          sort: [
            {
              field: sortColumnName,
              order: sortDirection.toUpperCase(),
            },
          ],
          totalCount: options.totalCount || false,
        }
      );
      return {
        pageData: res.nodes,
        totalRows: res.totalCount || null,
      };
    }
    render() {
      return (
        <EntityDataTable
          entityDomain={this.appModuleBuildDomain}
          rowControlsRightPopupMenuItems={(row, dataTable) => {
            return [
              <PopUpMenuItem
                icon='refresh'
                label='Rebuild'
                key='rebuild'
                onPress={() => this.handleRebuildPress(row.uid)}
              />,
              <PopUpMenuItem
                icon='fileDownload'
                label='Download'
                key='download'
                onPress={() =>
                  this.props.app.api.download(
                    `/downloads/appModuleBuild/${row.uid}`
                  )
                }
              />,
            ];
          }}
          searchable
          title='App Builds'
          name='appModuleBuilds'
          columns={{
            appModule: {
              label: "Name",
              sortable: true,
            },
            version: {
              label: "Version",
              sortable: true,
              width: 72
            },
            state: {
              label: "State",
              sortable: true,
              width: 144
            },
            createdAt: {
              label: "Created",
              sortable: true,
              width: 208
            },
          }}
          cellRenderer={({ cell, columnName, row, columns }) => {
            let ret = null;
            switch (columnName) {
              case "appModule":
                ret = cell.name;
                break;
              case "createdAt":
                let date = new Date(cell);
                ret = `${this.props.app.utils.date.toShortDate(
                  date
                )} ${this.props.app.utils.date.toTime(date)}`;
                break;
              case "state":
                ret = (
                  <StateIndicatorText
                    label={this.appModuleBuildDomain.getStateLabel(row.state)}
                    color={this.appModuleBuildDomain.getStateIndicatorColor(
                      row.state
                    )}
                  />
                );
                break;
              default:
                ret = cell;
            }
            return <Text style={[styles.cellText]}>{ret}</Text>;
          }}
          pageLoader={this.pageLoader}
          initialSortColumnName='updatedAt'
          initialSortDirection='desc'
          perms={this.perms}
          projectUid={this.props.projectUid}
          projectType={this.props.projectType}
          autoRefresh={30}
        />
      );
    }
  }
);
const styles = StyleSheet.create({
  cellText: {
    color: Colors.onSurface,
  },
});
