import React from "react";
import R14, {
  StyleSheet,
  ScreenLayout,
  View,
  Surface,
  SectionHeader,
  Text,
  Button,
  IconButton,
  PopUpMenu,
  PopUpMenuItem,
  Colors,
} from "../core";
import Dashboard from "../components/Dashboard";
import DashboardWidget from "../components/DashboardWidget";
import DashboardCard from "../components/DashboardCard";
import DashboardCardSection from "../components/DashboardCardSection";
import DashboardCardSectionRow from "../components/DashboardCardSectionRow";
import ProjectScreenLayout from "../components/ProjectScreenLayout";
import ManualEntryPipelineMetricsDataTable from "../components/ManualEntryPipelineMetricsDataTable";
export default R14.connect(
  class ProjectManualEntryPipelineScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleRefreshPress = this.handleRefreshPress.bind(this);
      this.userDomain = this.props.app.dm.user;
      this.state = {
        metrics: this.props.manualEntryPipeline.metrics,
        lastMetricsSyncAt: this.props.manualEntryPipeline.lastMetricsSyncAt,
      };
      this.perms = this.props.app.dm.user.getPermissions(
        this.props.app.dm.project.getKeyByType(this.props.project.type),
        "manualEntryPipeline"
      );
    }
    async handleRefreshPress() {
      this.props.app.ui.progressIndicator.show();
      // Auto-batch the fetch by resolving asynchronously
      let promiseRes = await Promise.all([
        this.props.app.dm.manualEntryPipeline.initDashboard(
          this.props.manualEntryPipeline.uid
        ),
        this.props.app.ui.dataTable("manualEntryDocumentMetrics").refresh(),
      ]);
      let manualEntryPipeline = promiseRes.length ? promiseRes[0] : null;
      this.props.app.ui.snackBar.show({
        message: manualEntryPipeline
          ? "Refreshed metrics."
          : "Unable to refresh metrics.",
        variant: manualEntryPipeline ? "success" : "error",
      });
      manualEntryPipeline &&
        this.setState({
          metrics: manualEntryPipeline.metrics,
          lastMetricsSyncAt: manualEntryPipeline.lastMetricsSyncAt,
        });
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
    }
    get manualEntryPipelineDomain() {
      return this.props.app.dm.manualEntryPipeline;
    }
    getMetric(parentType, type) {
      if (!this.state.metrics) return 0;
      return (
        this.state.metrics
          .filter(
            (metric) => metric.parentType === parentType && metric.type === type
          )
          .map((metric) => metric.value || 0)
          .at(0) || 0
      );
    }
    get dashboardSectionRows() {
      let defaultSections = [
        {
          title: "Queued",
          metricType:
            this.manualEntryPipelineDomain.METRIC_TYPE_TOTAL_AVAILABLE,
        },
        {
          title: "In Progress",
          metricType: this.manualEntryPipelineDomain.METRIC_TYPE_TOTAL_QUEUED,
        },
        {
          title: "Total",
          metricType: this.manualEntryPipelineDomain.METRIC_TYPE_TOTAL,
        },
      ];
      return [
        {
          title: "Document Sets",
          metricParentType:
            this.manualEntryPipelineDomain.METRIC_PARENT_TYPE_DOCUMENT_SET,
          sections: defaultSections,
        },
        {
          title: "Documents",
          metricParentType:
            this.manualEntryPipelineDomain.METRIC_PARENT_TYPE_DOCUMENT,
          sections: defaultSections,
        },
        {
          title: "Characters",
          metricParentType:
            this.manualEntryPipelineDomain.METRIC_PARENT_TYPE_CHARACTER,
          sections: defaultSections,
        },
        {
          title: "Fields",
          metricParentType:
            this.manualEntryPipelineDomain.METRIC_PARENT_TYPE_FIELD,
          sections: defaultSections,
        },
        {
          title: "Field Groups",
          metricParentType:
            this.manualEntryPipelineDomain.METRIC_PARENT_TYPE_FIELD_GROUP,
          sections: defaultSections,
        },
        {
          title: "Users",
          metricParentType:
            this.manualEntryPipelineDomain.METRIC_PARENT_TYPE_USER,
          sections: [
            {
              title: "Active",
              metricType:
                this.manualEntryPipelineDomain.METRIC_TYPE_TOTAL_ACTIVE,
            },
          ],
        },
      ];
    }
    renderLastMetricsSyncAt() {
      let formattedDate = "N/A";
      if (this.state.lastMetricsSyncAt) {
        let lastMetricsSyncAt = new Date(this.state.lastMetricsSyncAt);
        formattedDate = `${this.props.app.utils.date.toShortDate(
          lastMetricsSyncAt
        )} ${this.props.app.utils.date.toTime(lastMetricsSyncAt)}`;
      }
      return formattedDate;
    }
    render() {
      if (!this.perms.read) return null;
      return (
        <ProjectScreenLayout
          headerTitle={this.props.manualEntryPipeline.name}
          scrollEnabled
          project={this.props.project}
          headerControlsRight={[
            <View key='lastMetricsSyncAt' style={styles.lastMetricsSyncAt}>
              <Text style={styles.lastMetricsSyncAtText}>
                Last Sync: {this.renderLastMetricsSyncAt()}
              </Text>
            </View>,
            <IconButton
              icon='refresh'
              key='refresh'
              onPress={this.handleRefreshPress}
            />,
          ]}
          breadCrumbs={[
            {
              label: "Manual Entry",
              to: {
                route: "projectManualEntryPipelines",
                params: {
                  uid: this.props.project.uid,
                  key: this.props.app.dm.project.getKeyByType(
                    this.props.project.type
                  ),
                },
              },
            },
          ]}
        >
          <Dashboard name='manualEntryPipeline' minWidth={352}>
            <DashboardCard title='Stats'>
              {this.dashboardSectionRows.map((sectionRow) => (
                <DashboardCardSectionRow
                  key={sectionRow.metricParentType}
                  title={sectionRow.title}
                >
                  {sectionRow.sections.map((section) => (
                    <DashboardCardSection
                      key={section.metricType}
                      title={section.title}
                      style={styles.dashboardCardSection}
                    >
                      <Text style={styles.dashboardCardSectionText}>
                        {this.getMetric(
                          sectionRow.metricParentType,
                          section.metricType
                        )}
                        {/* {this.state.metrics.documents.totalAvailable} */}
                      </Text>
                    </DashboardCardSection>
                  ))}
                </DashboardCardSectionRow>
              ))}
            </DashboardCard>

            <DashboardWidget>
              <ManualEntryPipelineMetricsDataTable
                title='Daily Stats'
                name='manualEntryDocumentMetrics'
                manualEntryPipeline={this.props.manualEntryPipeline}
              />
            </DashboardWidget>
          </Dashboard>
        </ProjectScreenLayout>
      );
    }
  }
);
const styles = StyleSheet.create({
  dashboardCardSection: {
    // width: "33.3333%",
    minWidth: "33.3333%",
  },
  dashboardCardSectionText: {
    fontSize: 20,
    fontWeight: 500,
  },
  lastMetricsSyncAt: {
    paddingTop: 4,
  },
  lastMetricsSyncAtText: {
    fontSize: 14,
    color: StyleSheet.color(Colors.onSurface).rgba(0.8),
  },
});
