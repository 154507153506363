import React from "react";
import R14, {
  Redirect,
  SelectMenuField,
  HiddenInputField,
  BackButton,
  Form,
  FieldGroup,
  SubmitButton,
  TextInputField,
  SwitchField,
  DialogFormField,
  FadeView,
  View,
  Text,
  StyleSheet,
  SectionHeader,
} from "../core";
import LabelView from "../components/LabelView";

export default R14.connect(
  class ManualEntryConfigEditScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.pricingConfigLoader = this.pricingConfigLoader.bind(this);

      this.state = {
        pipelineBlockSelections: [],
      };
    }
    get isEditMode() {
      return this.props.formData.values && this.props.formData.values.uid
        ? true
        : false;
    }
    get formData() {
      return this.props.formData;
    }
    get form() {
      return this.props.app.ui.form("manualEntryConfigForm");
    }
    async pricingConfigLoader(filters = {}) {
      return await this.props.app.dm.manualEntryPricingConfig.fetchSelections(
        filters,
        {
          resultsPerPage: 500,
        }
      );
    }

    // handlePipelineBlocksRenderer(values) {
    //   if (
    //     !this.pipelineMap[values.pipelineUid] ||
    //     !this.pipelineMap[values.pipelineUid].pipelineBlocks[
    //       values.pipelineBlockUid
    //     ]
    //   )
    //     throw new Error("Pipeline map render error");
    //   return (
    //     <View>
    //       <View style={styles.permissionItemRow}>
    //         <LabelView
    //           style={styles.permissionItemLabelView}
    //           key='pipeline'
    //           label='Pipeline'
    //         >
    //           <Text>{this.pipelineMap[values.pipelineUid].name}</Text>
    //         </LabelView>
    //         <LabelView
    //           style={styles.permissionItemLabelView}
    //           key='pipelineBlock'
    //           label='Pipeline Block'
    //         >
    //           <Text>
    //             {
    //               this.pipelineMap[values.pipelineUid].pipelineBlocks[
    //                 values.pipelineBlockUid
    //               ].name
    //             }
    //           </Text>
    //         </LabelView>
    //       </View>
    //     </View>
    //   );
    // }

    // handlePipelineBlocksDialogSubmit(form) {
    //   let values = form.values;
    //   console.log("this.pipelineMap", this.pipelineMap);
    //   if (!this.pipelineMap[values.pipelineUid])
    //     throw new Error("Pipeline map error.");
    //   if (
    //     !this.pipelineMap[values.pipelineUid].pipelineBlocks[
    //       values.pipelineBlockUid
    //     ]
    //   ) {
    //     for (let pipelineBlock of this.state.pipelineBlockSelections) {
    //       if (pipelineBlock.value === values.pipelineBlockUid) {
    //         this.pipelineMap[values.pipelineUid].pipelineBlocks[
    //           values.pipelineBlockUid
    //         ] = {
    //           uid: pipelineBlock.value,
    //           name: pipelineBlock.label,
    //         };
    //         break;
    //       }
    //     }
    //     if (
    //       !this.pipelineMap[values.pipelineUid].pipelineBlocks[
    //         values.pipelineBlockUid
    //       ]
    //     )
    //       throw new Error("Pipeline block map error.");
    //   }
    //   // this.setState({ pipelineBlockSelections: [] });
    //   return true;
    // }
    // handlePipelineBlocksDialogClose() {
    //   this.setState({ pipelineBlockSelections: [] });
    // }

    async handleSubmit(form) {
      let editMethod = this.isEditMode ? "update" : "create";
      let ret = null;
      this.props.app.ui.progressIndicator.show();
      try {
        let isSuccess = await this.props.app.dm.manualEntryConfig[editMethod](
          form.values
        );
        if (isSuccess) {
          let dataTable = this.props.refreshDataTable || "manualEntryConfigs";
          this.props.app.ui.dataTable.exists(dataTable) &&
            (await this.props.app.ui.dataTable(dataTable).refresh());
          ret = <Redirect to={this.props.redirect || "manualEntryConfigs"} />;
        }
      } catch (err) {
        form.addError(err.message);
      }
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
      return ret;
    }

    render() {
      return (
        <Form
          name='manualEntryConfigForm'
          onSubmit={this.handleSubmit}
          validateBeforeSubmit
          initialValues={this.formData.values}
          controlsBottomRight={[
            <BackButton title='Cancel' key='cancel' variant='text' />,
            <SubmitButton
              title={this.isEditMode ? "Update" : "Create"}
              key='submit'
            />,
          ]}
        >
          {this.isEditMode && <HiddenInputField name='uid' />}
          <TextInputField name='name' label='Name' required={true} />
          <TextInputField name='key' label='Key' required={true} />
          {/* <FieldGroup>
            <TextInputField name='name' label='Name' required={true} />
            <SelectMenuField
              useValuesOnly
              name='projectUid'
              label='Project'
              required='Please select a project'
              items={this.formData.projectSelections}
            />
          </FieldGroup> */}
          <TextInputField name='description' label='Description' />
          <SelectMenuField
            useValuesOnly
            multiple
            name='manualEntryPipelineUids'
            label='Manual Entry Projects'
            items={this.formData.manualEntryPipelineSelections}
            required='Please select a manual entry project.'
          />
          <SelectMenuField
            useValuesOnly
            multiple
            name='modes'
            label='Modes'
            items={this.formData.modeSelections}
            required='Please select a mode.'
          />
          {/* <DialogFormField
            name='pipelineBlocks'
            dialogTitle='Pipeline Blocks'
            label='Pipeline Blocks'
            formName='manualEntryConfigForm'
            itemRenderer={this.handlePipelineBlocksRenderer}
            onSubmit={this.handlePipelineBlocksDialogSubmit}
            onDialogClose={this.handlePipelineBlocksDialogClose}
            onItemEditPress={this.handlePipelineBlocksItemEditPress}
          >
            <HiddenInputField
              name='type'
              value={
                this.props.app.dm.manualEntryConfig
                  .PERMISSION_TYPE_PIPELINE_BLOCK
              }
            />
            <SelectMenuField
              useValuesOnly
              name='pipelineUid'
              label='Pipeline'
              items={this.formData.pipelineSelections}
              required='Please select a pipeline.'
              onValueChange={this.handlePipelineValueChange}
            />
            <FadeView
              unmountOnExit
              visible={this.state.pipelineBlockSelections.length ? true : false}
            >
              {this.state.pipelineBlockSelections.length ? (
                <SelectMenuField
                  useValuesOnly
                  name='pipelineBlockUid'
                  label='Pipeline Block'
                  items={this.state.pipelineBlockSelections}
                  required='Please select a pipeline block.'
                />
              ) : null}
            </FadeView>
          </DialogFormField> */}
          <FieldGroup>
            <TextInputField
              name='itemsPerQueue'
              label={"Items Per Queue"}
              required={true}
            />
            <TextInputField
              name='charactersPerItem'
              label={"Characters Per Item"}
              required={true}
            />
          </FieldGroup>
          <FieldGroup>
            <TextInputField
              name='queuedTimeoutSeconds'
              label={"Queued Item Timeout"}
              required={true}
              helper='Seconds'
            />
            <TextInputField
              name='accuracyTestProbability'
              label={"Accuracy Test Probability"}
              helper='Between 0 and 1'
              required={false}
            />
          </FieldGroup>
          <DialogFormField
            name='queueNamespaces'
            dialogTitle='Add Namespace'
            label='Queue Namespaces'
            formName='manualEntryConfigForm'
            itemRenderer={(values) => (
              <View style={styles.fieldValueItem}>
                <Text>{values.queueNamespace}</Text>
              </View>
            )}
            helper='Tag Value Namespace separated by "/". Example: "cust1/prj1"'
          >
            <TextInputField
              name='queueNamespace'
              label='Tag Value Namespace'
              required='Please enter a field.'
            />
          </DialogFormField>
          {/* <FieldGroup>
            <TextInputField
              name='queuedTimeoutSeconds'
              label={"Queued Item Timeout"}
              required={true}
              helper='Seconds'
            />
            <TextInputField
              name='reviewTimeoutSeconds'
              label={"Review Timeout"}
              required={true}
              helper='Seconds'
            />
          </FieldGroup> */}
          <SwitchField
            name='exclusiveQueueNamespaces'
            label='Exclusive Queue Namespaces'
            helper='Results only returned by namespace'
            value={true}
          />
          <SwitchField name='active' label='Is Active' value={true} />
          <SectionHeader style={styles.sectionHeader} level={2}>App Options</SectionHeader>
          <SelectMenuField
            useValuesOnly
            multiple
            name='appFeatures'
            label='App Features'
            items={this.formData.appFeatureSelections}
          />
          <SelectMenuField
            useValuesOnly
            name='initialDocumentScaleMode'
            label='Initial Document Scale Mode'
            items={this.formData.initialDocumentScaleModeSelections}
            required='Please select a mode.'
          />
        </Form>
      );
    }
  }
);

const styles = StyleSheet.create({
  sectionHeader: {
    fontSize: 16,
  },
});
