import R14 from "../core";
export default class ManualEntryDomain extends R14.Domain {
  constructor(key) {
    super();
    this.MODE_FIELD_CHARACTERS = "FIELD_CHARACTERS";
    this.MODE_CHARACTERS = "CHARACTERS";
    this.MODE_FIELD = "FIELD";
    this.MODE_DOCUMENT_SET_FIELDS = "DOCUMENT_SET_FIELDS";

    this.state = {};
  }
  get metricFields() {
    return this.getMetricFields();
  }
  getMetricFields() {
    return [
      "cmtc",
      "cmtcu",
      "cmtcr",
      "fcmtc",
      "fcmtcu",
      "fcmtcr",
      "fcmt",
      "fcmtfu",
      "fcmtfr",
      "fmt",
      "fmtfu",
      "fmtfr",
      "dsfmt",
      "dsfmtfu",
      "dsfmtfr",
    ];
  }
  getMetricFieldInfo() {
    return [
      this.dm.manualEntry.MODE_CHARACTERS,
      this.dm.manualEntry.MODE_FIELD_CHARACTERS,
      this.dm.manualEntry.MODE_FIELD,
      this.dm.manualEntry.MODE_DOCUMENT_SET_FIELDS,
    ].map((mode) => {
      let prefix = null;
      let label = null;
      switch (mode) {
        case this.dm.manualEntry.MODE_CHARACTERS:
          prefix = "cm";
          label = "Characters";
          break;
        case this.dm.manualEntry.MODE_FIELD_CHARACTERS:
          prefix = "fcm";
          label = "Field Characters";
          break;
        case this.dm.manualEntry.MODE_FIELD:
          prefix = "fm";
          label = "Fields";
          break;
        case this.dm.manualEntry.MODE_DOCUMENT_SET_FIELDS:
          prefix = "dsfm";
          label = "Document Set Fields";
          break;
      }
      let fields = this.metricFields
        .filter((field) => field.startsWith(prefix))
        .map((name) => {
          let type = name.substring(prefix.length);
          let label = null;
          switch (type) {
            case "tc":
              label = "Total Characters";
              break;
            case "tcu":
              label = "Updated Characters";
              break;
            case "tcr":
              label = "Rejected Characters";
              break;
            case "t":
              label = "Total Fields";
              break;
            case "tfu":
              label = "Updated Fields";
              break;
            case "tfr":
              label = "Rejected Field";
              break;
          }
          return {
            name,
            label,
          };
        });
      return { mode, label, fields };
    });
  }
  get pricingMetricFields() {
    return this.getPricingMetricFields();
  }
  getPricingMetricFields() {
    return [
      "cmtc",
      "cmtcu",
      "cmtcr",
      "fcmtc",
      "fcmtcu",
      "fcmtcr",
      "fcmt",
      "fcmtfu",
      "fcmtfr",
      "fmt",
      "fmtfu",
      "fmtfr",
      "dsfmt",
      "dsfmtfu",
      "dsfmtfr",
    ];
  }
  getPricingMetricFieldInfo() {
    return [
      this.MODE_CHARACTERS,
      this.MODE_FIELD_CHARACTERS,
      this.MODE_FIELD,
    ].map((mode) => {
      let prefix = null;
      let label = null;
      switch (mode) {
        case this.MODE_CHARACTERS:
          prefix = "cm";
          label = "Characters";
          break;
        case this.MODE_FIELD_CHARACTERS:
          prefix = "fcm";
          label = "Field Characters";
          break;
        case this.MODE_FIELD:
          prefix = "fm";
          label = "Fields";
          break;
        case this.dm.manualEntry.MODE_DOCUMENT_SET_FIELDS:
          prefix = "dsfm";
          label = "Document Set Fields";
          break;
      }
      let fields = this.getPricingMetricFields()
        .filter((field) => field.startsWith(prefix))
        .map((name) => {
          let type = name.substring(prefix.length);
          let label = null;
          switch (type) {
            case "tc":
              label = "Total Characters";
              break;
            case "tcu":
              label = "Updated Characters";
              break;
            case "tcr":
              label = "Rejected Characters";
              break;
            case "t":
              label = "Total Fields";
              break;
            case "tfu":
              label = "Updated Fields";
              break;
            case "tfr":
              label = "Rejected Field";
              break;
          }
          return {
            name,
            label,
          };
        });
      return { mode, label, fields };
    });
  }
}
