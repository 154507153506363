import R14 from "../core";

export default class ManualEntryRouteDomain extends R14.Domain {
  constructor(key) {
    super();
    this.state = {};
    this.IO_TYPE_S3 = "S3";
  }
  async find(fieldsStr, options = null) {
    // let fieldsStr = this.utils.gql.fieldsToString(fields);
    if (!fieldsStr)
      throw new Error("Resource Domain Find Error: No fields found");
    // Add Client Filter
    if (!options.filter) options.filter = {};
    if (!options.totalCount) options.totalCount = false;
    options.filter.clientUid = { eq: this.dm.userSession.clientUid };

    let result = await this.api.qry(
      `
      query FindManualEntryRoutes($page: Int, $resultsPerPage: Int, $totalCount: Boolean!, $sort: [SortOption!]!, $filter: ManualEntryRouteFilter) {
        manualEntryRoutes(page: $page, resultsPerPage: $resultsPerPage, sort: $sort, filter: $filter){
          totalCount @include(if: $totalCount)
          nodes {
            ${fieldsStr}
          }
        }
      }`,
      options
    );
    return result.data.manualEntryRoutes;
  }
  async get(uid, options = {}) {
    let projectField = "";
    let qryParams = ["$uid: ID!"];
    let qryVals = {
      uid: uid,
    };

    if (options.project) {
      projectField = `
    project {
      key
      uid
      name
      type
    }
    `;
    }

    let res = await this.api.qry(
      `
      query GetManualEntryRoute(${qryParams.join(", ")}) {
       manualEntryRoute(uid: $uid){
          uid
          name
          pipelineBlockUids
          ${projectField}
        }
      }`,
      qryVals
    );
    return res.data.manualEntryRoute;
  }

  async fetchEditFormData(uid = null) {
    let qryVals = {
      manualEntryPipelineFilter: {
        clientUid: { eq: this.dm.userSession.clientUid },
      },
      manualEntryPipelineSort: [
        {
          field: "name",
          order: "ASC",
        },
      ],
      manualEntryPricingRouteFilter: {
        clientUid: { eq: this.dm.userSession.clientUid },
      },
      manualEntryPricingRouteSort: [
        {
          field: "name",
          order: "ASC",
        },
      ],
    };

    if (uid) {
      qryVals.uid = uid;
    }

    let res = uid
      ? await this.api.qry(
          `
          query ManualEntryRouteEditFormData($uid: ID!, $manualEntryPipelineFilter: ManualEntryPipelineFilter, $manualEntryPipelineSort: [SortOption!]!) {
            manualEntryRoute(uid: $uid){
              uid
              name
              description
              key
              manualEntryPipelineUids
              input {
                type
                bucketName
                folderName
                copyToFolderName
              }
              output {
                type
                bucketName
                folderName
              }
              timeoutMinutes
              timeoutTagValues {
                name
                value
                minutes
              }
            }
            manualEntryPipelines (filter: $manualEntryPipelineFilter, sort: $manualEntryPipelineSort) {
              nodes {
                uid
                name
              }
            }
          }`,
          qryVals
        )
      : await this.api.qry(
          `
          query ManualEntryRouteCreateFormData( $manualEntryPipelineFilter: ManualEntryPipelineFilter, $manualEntryPipelineSort: [SortOption!]!) {
            manualEntryPipelines (filter: $manualEntryPipelineFilter, sort: $manualEntryPipelineSort) {
              nodes {
                uid
                name
              }
            }
          }`,
          qryVals
        );

    console.log("RES DATA", res.data);
    let formVals = {};
    let formData = {
      values: {},
      // ioTypeSelections: [
      //   {
      //     label: "S3",
      //     value: this.IO_TYPE_S3,
      //   },
      // ],
      manualEntryPipelineSelections:
        res.data.manualEntryPipelines && res.data.manualEntryPipelines.nodes
          ? res.data.manualEntryPipelines.nodes.map((val) => ({
              label: val.name,
              value: val.uid,
            }))
          : [],
    };
    formVals.inputType = this.IO_TYPE_S3;
    formVals.outputType = this.IO_TYPE_S3;
    if (res.data && res.data.manualEntryRoute) {
      let values = res.data.manualEntryRoute;
      [
        "uid",
        "name",
        "key",
        "description",
        "manualEntryPipelineUids",
        "timeoutMinutes",
        "timeoutTagValues"
      ].forEach((field) => {
        formVals[field] = values[field] || null;
      });
      let input = res.data.manualEntryRoute.input;
      if(input){
        formVals.inputType = input.type;
        formVals.inputBucketName = input.bucketName;
        formVals.inputFolderName = input.folderName;
        formVals.inputCopyToFolderName = input.copyToFolderName;
      }
      let output = res.data.manualEntryRoute.output;
      if(output){
        formVals.outputType = output.type;
        formVals.outputBucketName = output.bucketName;
        formVals.outputFolderName = output.folderName;
      }
    }
    formData.values = formVals;
    return formData;
  }
  async fetchSelections(filters, options = {}) {
    let filter = {};
    if (filters.search) {
      filter.search = { like: `%${filters.search}%` };
    }
    let res = await this.find(
      `
      uid
      name
      `,
      {
        page: 1,
        resultsPerPage: options.resultsPerPage || 25,
        filter: filter,
        totalCount: false,
        sort: [
          {
            field: "name",
            order: "ASC",
          },
        ],
        clientUid: false,
      }
    );
    let ret =
      res && res.nodes
        ? res.nodes.map((val) => {
            let ret = {
              label: val.name,
              value: val.uid,
            };
            if (options.currencyUid) ret.currencyUid = val.currencyUid;
            return ret;
          })
        : [];
    return ret;
  }
  async create(values) {
    let res = await this.api.mutate(
      `
      mutation CreateManualEntryRoute($input: CreateManualEntryRouteInput!) {
        createManualEntryRoute(input: $input){
          manualEntryRoute {
            uid
            name
          }
        }
      }`,
      {
        input: this.parseFormValues(values),
      }
    );
    return true;
  }
  async update(values) {
    let res = await this.api.mutate(
      `
      mutation UpdateManualEntryRoute($input: UpdateManualEntryRouteInput!) {
        updateManualEntryRoute(input: $input){
          manualEntryRoute {
            uid
            name
          }
        }
      }`,
      {
        input: this.parseFormValues(values),
      }
    );

    return true;
  }
  parseFormValues(values) {
    let ret = {
      clientUid: this.dm.userSession.clientUid,
    };
    [
      "name",
      "key",
      "description",
      "manualEntryPipelineUids",
    ].forEach((field) => {
      ret[field] = values[field] || null;
    });
    if(values.uid) ret.uid = values.uid;
    if(values.timeoutTagValues) ret.timeoutTagValues = values.timeoutTagValues.map(tag=>({
      ...tag,
      minutes: parseInt(tag.minutes)
    }));
    if (values.timeoutMinutes)
      ret.timeoutMinutes = parseInt(values.timeoutMinutes);
    ret.input = {
      type: values.inputType,
      bucketName: values.inputBucketName,
      folderName: values.inputFolderName,
      copyToFolderName: values.inputCopyToFolderName,
    };
    ret.output = {
      type: values.outputType,
      bucketName: values.outputBucketName,
      folderName: values.outputFolderName,
    };
    return ret;
  }
  async delete(uid) {
    let res = await this.api.mutate(
      `
      mutation DeleteManualEntryRoute($uid: ID!) {
        deleteManualEntryRoute(uid: $uid){
          user {
            uid
            name
          }
        }
      }`,
      {
        uid: uid,
      }
    );
    return true;
  }
}
